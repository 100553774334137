import React, { useState, useEffect, useRef } from 'react'
import { plainAxios } from '../../utils/api-config'
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth'
import { useHistory } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  FormText
} from 'reactstrap'
import { message } from 'antd'
import { resolveError } from '../../components/Errors/ErrorSummary'
require('../firebase')

export default function Login() {
  const [mynumber, setnumber] = useState('')
  const [otp, setotp] = useState('')
  const [show, setshow] = useState(false)
  const [error, setError] = useState(false)
  const [final, setfinal] = useState('')
  const [validationError, setValidationError] = useState(false)
  const codeInputRef = useRef(null)
  const history = useHistory()
  const auth = getAuth()
  auth.languageCode = 'sv'

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible',
      'callback': (result) => {
        console.log(result)
      }
    }, auth)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(show && codeInputRef.current)
      codeInputRef.current.focus()
  }, [show])

  const signin = () => {

    if (mynumber === '' || mynumber.length < 10) {
      return
    } else {
      if (testNumber(mynumber)) {
        setValidationError(false)
        const appVerifier = window.recaptchaVerifier
        signInWithPhoneNumber(auth, mynumber, appVerifier).then((confirmationResult) => {
          setfinal(confirmationResult)
          setshow(true)
        })
          .catch((err) => {
            alert(err)
            window.location.reload()
          })
      }
    }
  }

  const validateOtp = () => {
    if (otp === null || final === null)
      return
    final.confirm(otp).then(async (result) => {
      const response = await plainAxios.post('/admin/users/logon', null, {
        headers: { Authorization: `Bearer ${result._tokenResponse.idToken}` }
      })

      const usersme = await plainAxios.get('/users/me', {
        headers: { Authorization: `Bearer ${result._tokenResponse.idToken}` }
      })

      if(response?.data?.granted) {
        localStorage.setItem('accessToken', JSON.stringify(result._tokenResponse.idToken))
        localStorage.setItem('user', JSON.stringify(result.user))
        localStorage.setItem('apiUser', JSON.stringify(usersme.data))
        history.push('/admin/dashboard')
      } else {
        message.error('Du måste vara inloggad som administratör.', 6)
        setotp('')
        setshow(false)
      }
    }).catch((err) => {
      if(err.isAxiosError) {
        resolveError(err)
        setshow(false)
      } else {
        setError(true)
      }
      setotp('')
      sessionStorage.clear()
      localStorage.clear()
    })
  }

  const testNumber = (number) => {
    if(/\+(?:[0-9]●?){10,10}[0-9]$/.test(number)) {
      setValidationError(false)
      return true
    } else {
      setValidationError(true)
      return false
    }
  }

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0 logincard">
          <CardBody className="px-lg-5 py-lg-5">
            <img
              alt="Logo"
              className="login-logo-img"
              src={require('../../assets/img/brand/uzit_logo.png')}
            />
            <h3 className='login-title mt-5'>Logga in med</h3>
            <div className="text-center text-muted mb-4">
              <small>Telefonnummer</small>
            </div>
            <Form role="form">
              <FormGroup style={{ display: !show ? 'block' : 'none' }}>
                <InputGroup className={mynumber ? 'input-group-alternative filledintext' : 'input-group-alternative'}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-mobile-button" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Telefonnummer +46"
                    type="text"
                    value={mynumber}
                    onChange={(e) => {setnumber(e.target.value); setValidationError(false)}}
                    name="phone"
                    autoComplete="new-password"
                    onKeyPress={(target) => {
                      if (target.charCode === 13) {
                        signin()
                      }
                    }
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup style={{ display: show ? 'block' : 'none' }}>
                <InputGroup className={mynumber ? 'input-group-alternative filledintext' : 'input-group-alternative'}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Verifieringskod"
                    type="text"
                    innerRef={codeInputRef}
                    value={otp}
                    onChange={(e) => setotp(e.target.value)}
                    name="otp"
                    autoComplete="new-password"
                    onKeyPress={(target) => {
                      if (target.charCode === 13) {
                        validateOtp()
                      }
                    }
                    }
                  />
                </InputGroup>
              </FormGroup>
              {error ? (
                <>
                  <FormText color="muted">
                      Telefon eller 2 stegs kod var felaktigt. Försök igen.
                  </FormText>
                </>
              ) : (
                validationError ? (
                  <>
                    <FormText color="muted">
                        Vänligen ange ett giltigt telefonnummer med +46 i början.
                    </FormText>
                  </>
                ) : null
              )}
              <div id="recaptcha-container"
                data-sitekey="AIzaSyCJgKgqsWaFx_bPh6QVxgqNmQNFxqFZ494"
                data-callback="sendForm"
                data-size="invisible">
              </div>
              <div style={{ display: !show ? 'block' : 'none' }} className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={signin}
                >
                    Skicka kod
                </Button>
              </div>
              <div style={{ display: show ? 'block' : 'none' }} className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={validateOtp}
                >
                    Logga in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}
