import React from 'react'
import { useState, useMemo, useRef } from 'react'
import { axios as apiClient } from '../../utils/api-config'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Spinner
} from 'reactstrap'
import 'antd/dist/antd.css'
import { Popconfirm, Select, Spin } from 'antd'
import Header from '../Headers/Header.js'
import { debounce, isEmpty, isNil } from 'lodash'

function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  const fetchRef = useRef(0)
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  )
}

async function fetchClubList(accessToken, value) {
  if(isNil(value) || isEmpty(value) || value.trim().length === 0) {
    return []
  }

  const {data: clubs} = await apiClient.get(`/admin/clubs?draft=false&take=25&skip=0&name=${encodeURIComponent(value)}`, { headers: { Authorization: `Bearer ${accessToken}`}})
  return clubs.map((club) => ({
    label: `${club.name}`,
    value: club.id
  }))
}

const ClubSearchComponent = ({ accessToken, loading, onClubSelected, onClosed, title, primaryBtnText, confirmText  }) => {
  const [clubId, setClubId] = useState(null)
  // Dropdown search selected item
  const [selectedClub, setSelectedClub] = useState()

  const onSelectedItemChanged = (selectedItem) => {
    setSelectedClub(selectedItem)
    if(isNil(selectedItem)){
      setClubId(null)
    } else {
      setClubId(selectedItem.value ?? null)
    }
  }

  return (
    <>
      <Header redColor={false} text={title} />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-club-search"
                          >
                            Sök efter klubb
                          </label>
                          <DebounceSelect
                            showSearch
                            allowClear
                            placeholder="Namn"
                            value={selectedClub}
                            fetchOptions={(value) => fetchClubList(accessToken, value)}
                            onChange={onSelectedItemChanged}
                            style={{ width: '100%' }}
                            disabled={loading}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row className='align-items-center justify-content-start'>
                      <Col lg="12">
                        {!loading ?
                          <>
                            <Popconfirm
                              placement="topLeft" title={confirmText}
                              onConfirm={(e) => { e.preventDefault(); onClubSelected(clubId)}}
                              okText="Ja" cancelText="Nej"
                            >
                              <Button color="success" type="submit" disabled={clubId === null}>
                                {primaryBtnText}
                              </Button>
                            </Popconfirm>
                            <Button color="danger" onClick={onClosed}>Tillbaka</Button>
                          </>
                          : (<Spinner color='danger' type='border' style={{marginLeft: '25px', marginTop: '5px'}}></Spinner>)
                        }
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default ClubSearchComponent