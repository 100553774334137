import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from 'reactstrap'
import 'antd/dist/antd.css'
import { BlockPicker } from 'react-color'
import { Select, Switch } from 'antd'
import Header from '../../../components/Headers/Header.js'
import ImageUpload from '../../../components/ImageUpload/ImageUpload'
import { saveImage } from '../../../utils/ImageHelper'
import { ErrorSummary, resolveError } from '../../../components/Errors/ErrorSummary'
import CouponListComponent from '../../../components/Coupon/CouponList'
import EntityLoaderComponent from '../../../components/Loader/EntityLoaderComponent'

class PartnerEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: null,
      errors: null,
      loading: true,
      notFound: false,
      clubs: [],
      selectedClubId: null,
    }

    this.getData = this.getData.bind(this)
    this.putData = this.putData.bind(this)
    this.handleInput = this.handleInput.bind(this)

    this.image = {
      changed: false,
      base64: null,
      imageUrl: null
    }
  }

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('accessToken'))
    if (token) {
      await this.getData(token, this.props.match.params.partnerId)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  async getData(accessToken, partnerId) {
    try {
      await this.setState({ loading: true })
      await this.loadRelatedData(accessToken)
      const response = await axios.get(`/admin/partners/${partnerId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.setState({
        object: {
          ...this.state.object,
          ...response.data,
        },
        selectedClubId: response.data?.club?.id
      })
      this.image.imageUrl = this.state.object.logo
    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  loadRelatedData = async (accessToken) => {
    const clubsResponse = await axios.get('/admin/clubs?take=10000&skip=0&orderBy=nameAsc', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    await this.setState({
      clubs: clubsResponse.data
    })
  }


  async putData(accessToken, partnerId, data) {
    try {
      await this.setState({ loading: true })
      this.image.imageUrl = this.image.changed ? await saveImage(accessToken, this.image) : this.image.imageUrl
      const tempData = {
        ...data,
        logo: this.image?.imageUrl,
        club: this.state.selectedClubId ? { id: this.state.selectedClubId } : null
      }
      await axios.put(`/partners/${partnerId}`,
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.history.push('/admin/partner')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  onImageChange = (base64Image) => {
    this.image.changed = true
    this.image.imageUrl = null
    this.image.base64 = base64Image
  }

  onBgColorChange = (color)  => {
    this.setState(prevState => ({
      object: {
        ...prevState.object,
        backgroundColor: color.hex
      }
    }))
  }

  onCouponDeleted = async (couponId) => {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        coupons: prevState.object.coupons.filter(c => c.id !== couponId)
      }
    }))
  }

  onCouponCreated = async (coupon) => {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        coupons: this.sortCouponsByName([...prevState.object.coupons, coupon])
      }
    }))
  }

  onCouponUpdated= async (coupon) => {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        coupons: this.sortCouponsByName([...prevState.object.coupons.filter(cpn => cpn.id !== coupon.id), coupon])
      }
    }))
  }

  sortCouponsByName = (coupons) => {
    return coupons.sort(function(a, b) {
      const nameA = a?.title.toUpperCase() ?? ''
      const nameB = b?.title.toUpperCase() ?? ''
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      // names must be equal
      return 0
    })
  }

  onSelectedClubChanged = (selectedItem) => {
    this.setState({ selectedClubId: selectedItem })
  }

  render = () => {
    const { object, selectedClubId } = this.state
    const { Option } = Select
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Ändra</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <ImageUpload imageUrl={object.logo} onChange={this.onImageChange} />
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                              >
                                Titel
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-title"
                                type="text"
                                value={object.title}
                                onChange={(e) => this.handleInput('title', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                              >
                                Ämne
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-topic"
                                type="text"
                                value={object.topic}
                                onChange={(e) => this.handleInput('topic', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                style={{paddingRight: 10}}
                                htmlFor="input-show-coupon-information"
                              >
                                Visa slumpad kupong information i annonsflödet
                              </label>
                              <Switch
                                id="input-show-coupon-information"
                                checkedChildren="Ja"
                                unCheckedChildren="Nej"
                                checked={object.showShortCouponInformation}
                                onChange={(checked) => this.handleInput('showShortCouponInformation', checked)} />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-bgColor"
                              >
                                Kulör
                              </label>
                              <BlockPicker
                                id="input-bgColor"
                                color={ object.backgroundColor ?? process.env.REACT_APP_DEFAULT_BG_COLOR }
                                onChange={ this.onBgColorChange }
                                triangle='hide'
                                width="100%"
                                colors={['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-flow-title"
                              >
                                Flödes titel
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-flow-title"
                                type="text"
                                value={object.flowTitle}
                                onChange={(e) => this.handleInput('flowTitle', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-desc"
                              >
                                Beskrivning
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-desc"
                                rows="7"
                                value={object.description}
                                onChange={(e) => this.handleInput('description', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" sm="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Klubb
                              </label>
                              <Select
                                showSearch
                                allowClear
                                value={selectedClubId}
                                className="form-control-alternative form-control"
                                placeholder="Sök efter klubb..."
                                optionFilterProp="children"
                                onChange={this.onSelectedClubChanged}
                                filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.clubs.map((item, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      <Option value={item.id}>{item.name}</Option>
                                    </React.Fragment>
                                  )
                                })}
                              </Select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <CouponListComponent
                                accessToken={this.props.accessToken}
                                partnerId={object.id}
                                coupons={object.coupons}
                                onCouponDeleted={(couponId) => this.onCouponDeleted(couponId)}
                                onCouponCreated={(coupon) => this.onCouponCreated(coupon)}
                                onCouponUpdated={(coupon) => this.onCouponUpdated(coupon)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        <Button
                          color="success"
                          type="submit"
                          onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.match.params.partnerId, this.state.object) }}
                        >
                          Spara
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return (
        <EntityLoaderComponent
          loading={this.state.loading}
          entityName="partnern"
          errors={this.state.errors}
          notFound={this.state.notFound} />
      )
    }
  }
}

export default withRouter(PartnerEdit)
