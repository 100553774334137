import React from 'react'
import {
  Row,
  Col
} from 'reactstrap'
import { message } from 'antd'

const ERROR_MESSAGE_DURATION = 6

const resolveErrorMessage = (error) => {
  if(error && (typeof error === 'string' || error instanceof String)) {
    return error
  }

  return 'Unexpected error'
}

const resolveError = (error) => {
  // Axios error handling docs
  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    console.log('resolveError: error.response', error.response)
    let exError = error.response.data.errors ?? { error: resolveErrorMessage(error.response.data.detail ?? error.response.data.title) }
    if(exError.error) {
      message.error(resolveErrorMessage(exError.error), ERROR_MESSAGE_DURATION)
    }
    return exError
  } else if (error.request) {
    // The request was made but no response was received. `error.request` is an instance of XMLHttpRequest in the browser
    console.log('resolveError: error.request', error.request)
    let exError = { error: resolveErrorMessage(error.toString()) }
    message.error(exError.error, ERROR_MESSAGE_DURATION)
    return exError
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('resolveError-else', error)
    let exError = { error: resolveErrorMessage(error?.message) }
    message.error(exError.error, ERROR_MESSAGE_DURATION)
    return exError
  }
}

const ErrorSummary = ({errors}) => {
  if (errors === null || typeof errors !== 'object') { //<--We expect it to be the error payload as defined in the API contract (no type/struct checking)
    return null
  }

  return (
    <Row>
      <Col sm="12" md="8" lg="6">
        <div className="pl-lg-4 bg-gradient-danger" style={{color: 'white', padding: 10, marginBottom: 15}}>
          {Object.keys(errors).map((key, index) => (
            <li key={index}>
              <b>{key}</b>: {errors[key]}<br></br>
            </li>
          ))}
        </div>
      </Col>
    </Row>
  )
}

export { ErrorSummary, resolveError }
