import React from 'react'
import { axios } from '../../utils/api-config'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardTitle,
  Spinner,
  FormText
} from 'reactstrap'
import 'antd/dist/antd.css'
import { Popconfirm } from 'antd'
import Header from '../Headers/Header.js'
import Loader from '../Loader/Loader'
import ImageUpload from '../ImageUpload/ImageUpload'
import { saveImage } from '../../utils/ImageHelper'
import { ErrorSummary, resolveError } from '../Errors/ErrorSummary'
import { isNullOrWhitespace, isValidUrl } from '../../utils/validators'

class CouponEditComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: null,
      errors: null,
      loading: true,
      notFound: false,
      selectedImage: false
    }

    this.getData = this.getData.bind(this)
    this.putData = this.putData.bind(this)
    this.handleInput = this.handleInput.bind(this)

    this.image = {
      changed: false,
      base64: null,
      imageUrl: null
    }
    this.thumbnail = {
      changed: false,
      base64: null,
      imageUrl: null
    }
  }

  async componentDidMount() {
    if (this.props.accessToken) {
      await this.getData(this.props.accessToken, this.props.couponId)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  async getData(accessToken, couponId) {
    try {
      await this.setState({ loading: true })
      const response = await axios.get(`/coupons/${couponId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.setState({
        object: {
          ...this.state.object,
          ...response.data,
        },
        selectedImage: response.data.image !== null
      })
      this.image.imageUrl = this.state.object.image
      this.thumbnail.imageUrl = this.state.object.thumbnail
    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({ loading: false })
    }
  }

  async putData(accessToken, couponId, data) {
    try {
      await this.setState({ loading: true })
      this.image.imageUrl = this.image.changed ? await saveImage(accessToken, this.image) : this.image.imageUrl
      this.thumbnail.imageUrl = this.thumbnail.changed ? await saveImage(accessToken, this.thumbnail) : this.thumbnail.imageUrl
      const tempData = {
        ...data,
        image: this.image.imageUrl,
        thumbnail: this.thumbnail.imageUrl
      }
      const couponResponse = await axios.put(`/coupons/${couponId}`,
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.onCouponUpdated({...couponResponse.data, consumedTimes: this.props.couponConsumedTimes })
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  clearConsumptions = async (accessToken, couponId) => {
    try {
      await this.setState({ loading: true })
      const couponResponse = await axios.put(`/coupons/${couponId}/clearconsumptions`, {}, { headers: { Authorization: `Bearer ${accessToken}` } })
      this.props.onCouponUpdated({...couponResponse.data, consumedTimes: 0 })
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  onImageChange = (key, base64Image) => {
    if(key === 'thumbnail') {
      this.thumbnail.changed = true
      this.thumbnail.imageUrl = null
      this.thumbnail.base64 = base64Image
    }
    if(key === 'image') {
      this.image.changed = true
      this.image.imageUrl = null
      this.image.base64 = base64Image
      this.setState({
        selectedImage: base64Image !== null
      })
    }
  }

  frmIsValid = ({ description, link, code, selectedImage }) => {
    return !isNullOrWhitespace(description)
           && (isNullOrWhitespace(link) || isValidUrl(link))
           && (selectedImage || !isNullOrWhitespace(code) || !isNullOrWhitespace(link))
  }

  render = () => {
    const { object, selectedImage } = this.state
    const isValid = this.frmIsValid({...this.state.object, selectedImage: this.state.selectedImage })
    if (object) {
      return (
        <>
          <Header redColor={true} text="ÄNDRA KUPONG" />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                              >
                                Titel
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-title"
                                type="text"
                                value={object.title}
                                onChange={(e) => this.handleInput('title', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <ImageUpload imageUrl={object.thumbnail} label={'Thumbnail'} onChange={(base64Image) => this.onImageChange('thumbnail', base64Image)} />
                        <Card>
                          <CardBody>
                            <CardTitle>
                              <h5>Alfabetisk kod / QR kod / Länk</h5>
                            </CardTitle>
                            {!selectedImage && !object.link && (
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-code"
                                    >
                                      Alfabetisk kod
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-code"
                                      type="text"
                                      value={object.code}
                                      onChange={(e) => this.handleInput('code', e.target.value)}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                            {!object.code && !object.link && <ImageUpload imageUrl={object.image} label={'QR kod'} onChange={(base64Image) => this.onImageChange('image', base64Image)} />}
                            {!selectedImage && !object.code && (
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-link"
                                    >
                                      Länk
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-link"
                                      type="text"
                                      value={object.link}
                                      onChange={(e) => this.handleInput('link', e.target.value)}
                                    />
                                    <FormText color="danger" style={{ display: object.link && !isValidUrl(object.link) ? 'block' : 'none' }}>
                                    * Länk måste vara ett giltig url.
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                          </CardBody>
                        </Card>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-desc"
                              >
                                Beskrivning
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-desc"
                                rows="7"
                                value={object.description}
                                onChange={(e) => this.handleInput('description', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-terms"
                              >
                                Villkor
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-terms"
                                rows="7"
                                value={object.terms ?? ''}
                                onChange={(e) => this.handleInput('terms', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        {!this.state.loading ?
                          <>
                            <Button
                              color="success"
                              type="submit"
                              disabled={!isValid}
                              onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.couponId, this.state.object) }}
                            >
                              Spara
                            </Button>
                            {this.props.couponConsumedTimes > 0 &&
                              <Popconfirm
                                placement="topLeft" title='Är du säker på att du vill rensa alla användningar? Alla som en gång har använt erbjudandet kommer kunna använda den på nytt.'
                                onConfirm={(e) => { e.preventDefault(); this.clearConsumptions(this.props.accessToken, this.props.couponId) }}
                                okText="Ja" cancelText="Nej"
                              >
                                <Button color="danger" type="submit" >
                                  Rensa alla användningar
                                </Button>
                              </Popconfirm>
                            }
                          </>
                          : (<Spinner color='danger' type='border' style={{marginLeft: '25px', marginTop: '5px'}}></Spinner>)
                        }
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      if(this.state.loading) {
        return (
          <>
            <Header />
            <Container className="mt-4" fluid>
              <h3 style={{textAlign: 'center', paddingTop: 25}}>
                <Loader items={5} color="danger" type="grow" size="sm" />
              </h3>
            </Container>
          </>
        )
      } else {
        return (
          <>
            <Header redColor={true} />
            <Container className="mt-4" fluid>
              <h1>
                Åh nej!
                <br />
                {this.state.notFound ? (
                  <>Kunde tyvärr inte hitta kupongen.</>
                ) : this.state.errors ? (
                  <ErrorSummary errors={this.state.errors} />
                ) : (
                  <>Ett okänt fel inträffade. Var vänlig försök igen.</>
                )}
              </h1>
            </Container>
          </>
        )
      }
    }
  };
}

export default CouponEditComponent
