import React from 'react'
import { Popover } from 'antd'
import { PropTypes } from 'prop-types'
import GrowthRateStatsDetails from './GrowthRateStatsDetails'

const GrowthRateStats = (props) => {

  const growthRateDetails = <GrowthRateStatsDetails growthStats={props.growthStats} unit={props.detailsUnit} />

  const content = (
    <p className="mt-3 mb-0 text-muted text-sm">
      <span className={props.growthStats?.growth >= 0 ? 'text-success mr-2' : 'text-danger mr-2'}>
        <i className={props.growthStats?.growth >= 0 ? 'fas fa-arrow-up' : 'fas fa-arrow-down'} />
        {Math.trunc(props.growthStats?.growth)}{props.unit}
      </span>
      &nbsp;
      <span className="text-nowrap">{props.description?.replace('#period#', props.growthStats?.period ?? 'N/A') ?? ''}</span>
    </p>
  )

  return (
    props.growthStats
      ?
      props.detailsVisible ?
        <Popover content={growthRateDetails} placement="bottom" title={`Tillväxthastighet (${props.growthStats?.period ?? 'N/A'} dagar)`}>
          {content}
        </Popover>
        :
        <>{content}</>
      :
      <p className="mt-3 mb-0 text-muted text-sm">
        <span className="text-nowrap">{props.noValDescription ?? ''}</span>
      </p>
  )
}

GrowthRateStats.defaultProps = {
  growthStats: null,
  unit: '',
  description:'Sen sista #period# dagar',
  noValDescription: 'Behöver ett värde från sista #period# dagar.',
  detailsVisible: false,
  detailsUnit: ''
}
GrowthRateStats.propTypes = {
  growthStats: PropTypes.shape({
    startDate: PropTypes.string.isRequired, //PropTypes.instanceOf(Date)
    period: PropTypes.number.isRequired,
    startingValue: PropTypes.number.isRequired,
    endingValue: PropTypes.number.isRequired,
    growth: PropTypes.number.isRequired,
  }),
  unit: PropTypes.string,
  description: PropTypes.string,
  noValDescription: PropTypes.string,
  detailsVisible: PropTypes.bool,
  detailsUnit: PropTypes.string
}


export default GrowthRateStats
