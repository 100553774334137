import React from 'react'
import { axios } from '../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from 'reactstrap'
import 'antd/dist/antd.css'
import Header from '../Headers/Header.js'
import ImageUpload from '../ImageUpload/ImageUpload'
import { saveImage } from '../../utils/ImageHelper'
import { ErrorSummary, resolveError } from '../Errors/ErrorSummary'

class CategoryCreateComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: {
        name: '',
        icon: '',
        parent: { id: this.props.parentCategory.id }
      },
      errors: null,
      loading: false
    }

    this.postData = this.postData.bind(this)
    this.handleInput = this.handleInput.bind(this)

    this.image = {
      base64: null,
      imageUrl: null
    }
  }

  async postData(accessToken, data) {
    try {
      await this.setState({loading: true})
      this.image.imageUrl = await saveImage(accessToken, this.image)
      const tempData = {
        ...data,
        icon: this.image?.imageUrl
      }
      const createResponse = await axios.post('/categories',
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.onCategoryCreated(createResponse.data)
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  onImageChange = (base64Image) => {
    this.image.imageUrl = null
    this.image.base64 = base64Image
  }

  render = () => {
    const { object } = this.state
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Skapa ny</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <ImageUpload imageUrl={null} onChange={this.onImageChange} />
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-parentName"
                              >
                                Underkategori till
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-parentName"
                                type="text"
                                value={this.props.parentCategory?.name ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Namn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.name}
                                onChange={(e) => this.handleInput('name', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        {!this.state.loading
                          ?
                          (<Button
                            color="success"
                            type="submit"
                            onClick={(e) => { e.preventDefault(); this.postData(this.props.accessToken, this.state.object) }}
                          >
                            Skapa
                          </Button>)
                          :
                          (<Spinner color='danger' type='border' style={{marginLeft: '25px', marginTop: '5px'}}></Spinner>)
                        }
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return null
    }
  };
}

export default withRouter(CategoryCreateComponent)
