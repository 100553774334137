import React from 'react'
import { axios } from '../../utils/api-config'
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from 'reactstrap'
import { Input } from 'antd'
import Header from '../Headers/Header.js'
import { resolvePagingOffset } from '../../utils/ListHelper'
import { resolveError } from '../Errors/ErrorSummary'

class ClubListComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      array: [],
      pageIndex: 0,
      orderBy: '',
      desc: null,
      nameFilter: null,
      txtSearch: ''
    }

    this.changePageIndex = this.changePageIndex.bind(this)
    this.onSort = this.onSort.bind(this)
  }

  componentDidMount() {
    const array = JSON.parse(sessionStorage.getItem(this.props.storageKey))
    const pageIndex = JSON.parse(sessionStorage.getItem(`${this.props.storageKey}-PageIndex`))
    const filter = JSON.parse(sessionStorage.getItem(`${this.props.storageKey}-Filter`))

    if (array !== null) {
      this.setState({
        array: array,
      })
    }

    if (pageIndex !== null) {
      this.setState({pageIndex: pageIndex})
    }

    if (filter !== null) {
      this.setState({nameFilter: filter, txtSearch: filter})
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if ((this.state.pageIndex !== prevState.pageIndex)
    || (this.state.orderBy !== prevState.orderBy)
    || (this.state.desc !== prevState.desc)
    || (this.state.nameFilter !== prevState.nameFilter)
    ) {
      await this.fetchData()
    }

    if (this.state.pageIndex !== prevState.pageIndex) {
      sessionStorage.setItem(`${this.props.storageKey}-PageIndex`, JSON.stringify(this.state.pageIndex))
    }
    if (this.state.nameFilter !== prevState.nameFilter) {
      sessionStorage.setItem(`${this.props.storageKey}-Filter`, JSON.stringify(this.state.nameFilter))
    }

    if (this.props.accessToken !== prevProps.accessToken) {
      await this.fetchData()
    }
  }

  fetchData = async () => {
    try {
      const skip = resolvePagingOffset(this.state.pageIndex, this.props.pageSize)
      const orderBy = this.state.orderBy ? `&orderBy=${this.state.orderBy}${this.state.desc ? 'Desc': 'Asc' }` : ''
      const filter = this.state.nameFilter ? `&name=${encodeURIComponent(this.state.nameFilter)}` : ''

      const response = await axios.get(`/admin/clubs?draft=false&take=${this.props.pageSize}&skip=${skip}${orderBy}${filter}`, {
        headers: {
          Authorization: `Bearer ${this.props.accessToken}`,
        },
      })
      await this.setState({
        array: response.data,
      })
      sessionStorage.setItem(this.props.storageKey, JSON.stringify(response.data))
    } catch (error) {
      resolveError(error)
    }
  }

  async changePageIndex(number, data, type) {
    if (type === 'sub') {
      if (number >= 0) {
        this.setState({
          pageIndex: number
        })
      }
    } else if (type === 'add') {
      if (number >= 0 && data.length >= this.props.pageSize) {
        this.setState({
          pageIndex: number
        })
      }
    }
  }

  renderSortIcon = (columnName) => {
    return this.state.orderBy === columnName ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null
  }

  async onSort(user, sortKey) {
    let desc = this.state.desc === null ? false : !this.state.desc
    await this.setState({
      orderBy: sortKey,
      desc,
    })
  }

  onSearch = (value) => {
    this.setState({
      nameFilter: value,
      pageIndex: 0
    })
  }

  navigate = (item) => {
    this.props.history.push(`${this.props.navigateUrl}/${item.id}`)
    window.location.reload()
  }

  render = () => {
    const { Search } = Input
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center justify-content-between">
                    <Col md="4">
                      <h3 className="mb-0">Välj klubb</h3>
                    </Col>
                    <Col md="4" className="text-md-center mb-2 mb-md-0">
                      <Search
                        placeholder="Sök efter klubb..."
                        allowClear
                        onSearch={this.onSearch}
                        style={{ width: 250 }}
                        value={this.state.txtSearch}
                        onChange={(e) => this.setState({ txtSearch: e.target.value})}
                      />
                    </Col>
                    <Col className="text-md-right" md="4">
                      &nbsp;
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Logotyp</th>
                      <th onClick={e => this.onSort(e, 'name')} scope="col">Namn {this.renderSortIcon('name')}</th>
                      <th onClick={e => this.onSort(e, 'swishPhoneNumber')} scope="col">Swish-nummer {this.renderSortIcon('swishPhoneNumber')}</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.array.length > 0
                      ? this.state.array.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.logo ? <img className="img-in-listtable mainpicture-with-radius" alt="Bild" src={item.logo} /> : null}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.name}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.swishPhoneNumber}
                                </span>
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      href={`/${this.props.navigateUrl}/${item.id}`}
                                    >
                                      {this.props.menuText}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                      : null}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {this.state.pageIndex !== 0 ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                            >
                              {(this.state.pageIndex).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem className="active">
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex, this.state.array, 'add') }}
                        >
                          {(this.state.pageIndex + 1).toString()}
                        </PaginationLink>
                      </PaginationItem>
                      {this.state.array.length >= this.props.pageSize ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                            >
                              {(this.state.pageIndex + 2).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem>
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  };
}

export default ClubListComponent
