import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  Input
} from 'reactstrap'
import 'antd/dist/antd.css'
import Header from '../../../components/Headers/Header.js'
import Loader from '../../../components/Loader/Loader'
import { ErrorSummary, resolveError } from '../../../components/Errors/ErrorSummary'
import { Popconfirm, message as AntdMessage, Input as AntdInput, Tabs } from 'antd'
import { AndroidOutlined, MailOutlined } from '@ant-design/icons'

const { TextArea } = AntdInput

class BroadcastMessage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTabKey: 'message',
      message: '',
      emailSubject: '',
      emailBody: '',
      loading: false,
      errors: null
    }
  }

  broadcastMessage = async (accessToken, message) => {
    try {
      this.setState({loading: true})
      await axios.put('admin/messaging/broadcast-message', { message }, { headers: { Authorization: `Bearer ${accessToken}` } })
      this.setState({message: ''})
      AntdMessage.success('Meddelandet skickades')
    } catch (error) {
      const errors = resolveError(error)
      this.setState({ errors })
    } finally {
      this.setState({
        loading: false,
        activeTabKey: 'message'
      })
    }
  }

  broadcastEmail = async (accessToken, subject, message) => {
    try {
      this.setState({loading: true})
      await axios.put('admin/messaging/broadcast-email', { subject, message }, { headers: { Authorization: `Bearer ${accessToken}` } })
      this.setState({
        emailBody: '',
        emailSubject: ''
      })
      AntdMessage.success('E-posten skickades')
    } catch (error) {
      const errors = resolveError(error)
      this.setState({ errors })
    } finally {
      this.setState({
        loading: false,
        activeTabKey: 'email'
      })
    }
  }

  handleInputChanged = (key, value) => {
    this.setState({[key]: value})
  }

  messageFrmIsValid = () => {
    return this.state.message && this.state.message.length <= 35
  }

  emailFrmIsValid = () => {
    return this.state.emailBody && this.state.emailBody.length > 0
      && this.state.emailSubject && this.state.emailSubject.length > 0
  }

  render = () => {
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Skicka notifikation</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {!this.state.loading
                    ?
                    <Tabs activeKey={this.state.activeTabKey}
                      onTabClick={(key) => this.setState({activeTabKey: key})}>
                      <Tabs.TabPane tab={<span><AndroidOutlined style={{ fontSize: '18px' }} />Notifikation</span>} key="message" type="card" size="large">
                        <Form>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-message"
                                  >
                                    Meddelande
                                  </label>
                                  <TextArea
                                    name="input-message"
                                    showCount
                                    allowClear
                                    maxLength={35}
                                    rows={4}
                                    value={this.state.message}
                                    onChange={(e) => {e.preventDefault(); this.handleInputChanged('message', e.target.value)}}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                          <div className="pl-lg-4">
                            <Popconfirm
                              placement="topLeft" title='Är du säker på att du vill skicka notisen? '
                              onConfirm={(e) => { e.preventDefault(); this.broadcastMessage(this.props.accessToken, this.state.message) }}
                              okText="Ja" cancelText="Nej"
                            >
                              <Button
                                color="success"
                                type="submit"
                                disabled={!this.messageFrmIsValid()}
                              >
                                Skicka meddelandet
                              </Button>
                            </Popconfirm>
                          </div>
                        </Form>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab={<span><MailOutlined style={{ fontSize: '18px' }}/>E-post</span>} key="email" type="card" size="large">
                        <Form>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="8">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-email-subject"
                                  >
                                    Ämne
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-email-subject"
                                    type="text"
                                    value={this.state.emailSubject}
                                    onChange={(e) => {e.preventDefault(); this.handleInputChanged('emailSubject', e.target.value)}}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="8">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-email-body"
                                  >
                                    Meddelande
                                  </label>
                                  <TextArea
                                    name="input-email-body"
                                    showCount
                                    rows={8}
                                    value={this.state.emailBody}
                                    onChange={(e) => {e.preventDefault(); this.handleInputChanged('emailBody', e.target.value)}}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                          <div className="pl-lg-4">
                            <Popconfirm
                              placement="topLeft" title='Är du säker på att du vill skicka e-posten? '
                              onConfirm={(e) => { e.preventDefault(); this.broadcastEmail(this.props.accessToken, this.state.emailSubject, this.state.emailBody) }}
                              okText="Ja" cancelText="Nej"
                            >
                              <Button
                                color="success"
                                type="submit"
                                disabled={!this.emailFrmIsValid()}
                              >
                                Skicka e-post
                              </Button>
                            </Popconfirm>
                          </div>
                        </Form>
                      </Tabs.TabPane>
                    </Tabs>
                    :
                    <Row className="align-items-center justify-content-center pt-5">
                      <Col xs="auto">
                        <Loader items={5} color="danger" type="grow" size="sm" />
                      </Col>
                    </Row>
                  }
                  <div className="pt-3 pl-lg-4">
                    <ErrorSummary errors={this.state.errors} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  };
}

export default withRouter(BroadcastMessage)
