import React from 'react'
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap'
import GrowthRateStats from '../Stats/GrowthRateStats'

const DashboardHeaderCard = (props) => {

  const growthRateStatsDetailsVisible = process.env.REACT_APP_SHOW_GROWTHRATE_DETAILS === 'true'

  return (
    <Card className="card-stats mb-4 mb-xl-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0"
            >
              {props.title ?? ''}
            </CardTitle>
            <span className="h2 font-weight-bold mb-0">{props.value} {props.unit}</span>
          </div>
          <Col className="col-auto">
            <div className={`icon icon-shape text-white rounded-circle shadow ${props.color}`}>
              <i className={`fas ${props.icon}`} />
            </div>
          </Col>
        </Row>
        {props.children ? props.children : props.footerStats && <GrowthRateStats {...(props.footerStats ?? {})} detailsVisible={growthRateStatsDetailsVisible} detailsUnit={props.unit}  />}
      </CardBody>
    </Card>
  )
}

export default DashboardHeaderCard
