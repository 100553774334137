import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormText
} from 'reactstrap'
import 'antd/dist/antd.css'
import Header from '../../../components/Headers/Header.js'
import { ErrorSummary, resolveError } from '../../../components/Errors/ErrorSummary'
import LocationListComponent from '../../../components/Locations/LocationList'
import { latitudeIsValid, longitudeIsValid } from '../../../utils/CoordinatesHelper'
import EntityLoaderComponent from '../../../components/Loader/EntityLoaderComponent'

class LocationEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: null,
      errors: null,
      loading: true,
      notFound: false,
      locations: []
    }

    this.getData = this.getData.bind(this)
    this.putData = this.putData.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('accessToken'))
    if (token) {
      await this.getData(token, this.props.match.params.locationId)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  async getData(accessToken, locationId) {
    try {
      await this.setState({ loading: true })
      const response = await axios.get(`/locations/${locationId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.loadRelatedData(accessToken, locationId)
      let { coordinates } = response.data
      await this.setState({
        object: {
          ...this.state.object,
          ...response.data,
          coordinates: {
            longitude: coordinates.longitude.toString(),
            latitude: coordinates.latitude.toString()
          }
        }
      })
    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  loadRelatedData = async (accessToken, locationId) => {
    const childLocationsResponse = await axios.get(`/admin/locations?parent=${locationId}&take=10000&skip=0&orderBy=nameAsc`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    await this.setState({
      locations: childLocationsResponse.data
    })
  }

  async putData(accessToken, locationId, data) {
    try {
      await this.setState({ loading: true })
      const tempData = {
        ...data,
        parent: null
      }
      await axios.put(`/locations/${locationId}`,
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.history.push('/admin/location')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    if(key === 'latitude' || key === 'longitude')
    {
      let { coordinates } = this.state.object
      coordinates[key] = value
      await this.setState(prevState => ({
        object: {
          ...prevState.object,
          coordinates
        }
      }))
    } else {
      await this.setState(prevState => ({
        object: {
          ...prevState.object,
          [key]: value
        }
      }))
    }
  }

  onLocationDeleted = async (locationId) => {
    await this.setState(prevState => ({
      locations: prevState.locations.filter(l => l.id !== locationId)
    }))
  }

  onLocationCreated = async (location) => {
    await this.setState(prevState => ({
      locations: this.sortLocationsByName([...prevState.locations, location])
    }))
  }

  onLocationUpdated= async (location) => {
    await this.setState(prevState => ({
      locations: this.sortLocationsByName([...prevState.locations.filter(l => l.id !== location.id), location])
    }))
  }

  sortLocationsByName = (locations) => {
    return locations.sort(function(a, b) {
      const nameA = a?.name.toUpperCase() ?? ''
      const nameB = b?.name.toUpperCase() ?? ''
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      // names must be equal
      return 0
    })
  }

  frmIsValid = ({ name, coordinates }) => {
    return name.length > 0
            && latitudeIsValid(coordinates)
            && longitudeIsValid(coordinates)
  }

  render = () => {
    const { object, locations } = this.state
    const isValid = object && this.frmIsValid({...this.state.object })
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Ändra</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Namn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.name}
                                onChange={(e) => this.handleInput('name', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-lat"
                              >
                                Latitude
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-lat"
                                type="text"
                                value={object.coordinates.latitude}
                                onChange={(e) => this.handleInput('latitude', e.target.value)}
                              />
                              <FormText color="danger" style={{ display: !latitudeIsValid(object.coordinates) ? 'block' : 'none' }}>
                                * Latitude måste vara en giltig siffra
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-long"
                              >
                                Longitude
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-long"
                                type="text"
                                value={object.coordinates.longitude}
                                onChange={(e) => this.handleInput('longitude', e.target.value)}
                              />
                              <FormText color="danger" style={{ display: !longitudeIsValid(object.coordinates) ? 'block' : 'none' }}>
                                * Longitude måste vara en giltig siffra
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <LocationListComponent
                                accessToken={this.props.accessToken}
                                parentLocation={object}
                                locations={locations}
                                onLocationDeleted={(locationId) => this.onLocationDeleted(locationId)}
                                onLocationCreated={(location) => this.onLocationCreated(location)}
                                onLocationUpdated={(location) => this.onLocationUpdated(location)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        <Button
                          color="success"
                          type="submit"
                          onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.match.params.locationId, this.state.object) }}
                          disabled={!isValid}
                        >
                          Spara
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return (
        <EntityLoaderComponent
          loading={this.state.loading}
          entityName="platsen"
          errors={this.state.errors}
          notFound={this.state.notFound} />
      )
    }
  }
}

export default withRouter(LocationEdit)
