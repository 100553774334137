import React from 'react'
import { axios } from '../../utils/api-config'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from 'reactstrap'
import 'antd/dist/antd.css'
import Header from '../Headers/Header.js'
import Loader from '../Loader/Loader'
import { ErrorSummary, resolveError } from '../Errors/ErrorSummary'

class LocationEditComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: null,
      errors: null,
      loading: true,
      notFound: false,
    }

    this.getData = this.getData.bind(this)
    this.putData = this.putData.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  async componentDidMount() {
    if (this.props.accessToken) {
      await this.getData(this.props.accessToken, this.props.locationId)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  async getData(accessToken, locationId) {
    try {
      await this.setState({ loading: true })
      const response = await axios.get(`/locations/${locationId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.setState({
        object: {
          ...this.state.object,
          ...response.data,
        }
      })
    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({ loading: false })
    }
  }

  async putData(accessToken, locationId, data) {
    try {
      await this.setState({ loading: true })
      const tempData = {
        ...data
      }
      const editResponse = await axios.put(`/locations/${locationId}`,
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.onLocationUpdated(editResponse.data)
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    if(key === 'latitude' || key === 'longitude')
    {
      let { coordinates } = this.state.object
      coordinates[key] = value
      await this.setState(prevState => ({
        object: {
          ...prevState.object,
          coordinates
        }
      }))
    } else {
      await this.setState(prevState => ({
        object: {
          ...prevState.object,
          [key]: value
        }
      }))
    }
  }

  render = () => {
    const { object } = this.state
    if (object) {
      return (
        <>
          <Header redColor={true} text="ÄNDRA PLATS" />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-parentName"
                              >
                                Underplats till
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-parentName"
                                type="text"
                                value={object.parent?.name ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Namn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.name}
                                onChange={(e) => this.handleInput('name', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-lat"
                              >
                                Latitude
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-lat"
                                type="text"
                                value={object.coordinates.latitude}
                                onChange={(e) => this.handleInput('latitude', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-long"
                              >
                                Longitude
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-long"
                                type="text"
                                value={object.coordinates.longitude}
                                onChange={(e) => this.handleInput('longitude', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        <Button
                          color="success"
                          type="submit"
                          onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.locationId, this.state.object) }}
                        >
                          Spara
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      if(this.state.loading) {
        return (
          <>
            <Header />
            <Container className="mt-4" fluid>
              <h3 style={{textAlign: 'center', paddingTop: 25}}>
                <Loader items={5} color="danger" type="grow" size="sm" />
              </h3>
            </Container>
          </>
        )
      } else {
        return (
          <>
            <Header redColor={true} />
            <Container className="mt-4" fluid>
              <h1>
                Åh nej!
                <br />
                {this.state.notFound ? (
                  <>Kunde tyvärr inte hitta platsen.</>
                ) : this.state.errors ? (
                  <ErrorSummary errors={this.state.errors} />
                ) : (
                  <>Ett okänt fel inträffade. Var vänlig försök igen.</>
                )}
              </h1>
            </Container>
          </>
        )
      }
    }
  };
}

export default LocationEditComponent
