import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from 'reactstrap'
import 'antd/dist/antd.css'
import Header from '../../../components/Headers/Header.js'
import ImageUpload from '../../../components/ImageUpload/ImageUpload'
import { saveImage } from '../../../utils/ImageHelper'
import { ErrorSummary, resolveError } from '../../../components/Errors/ErrorSummary'
import CategoryListComponent from '../../../components/Category/CategoryList'
import EntityLoaderComponent from '../../../components/Loader/EntityLoaderComponent'

class CategoryEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: null,
      errors: null,
      loading: true,
      notFound: false,
      categories: []
    }

    this.getData = this.getData.bind(this)
    this.putData = this.putData.bind(this)
    this.handleInput = this.handleInput.bind(this)

    this.image = {
      changed: false,
      base64: null,
      imageUrl: null
    }
  }

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('accessToken'))
    if (token) {
      await this.getData(token, this.props.match.params.categoryId)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  async getData(accessToken, categoryId) {
    try {
      await this.setState({loading: true})
      const response = await axios.get(`/categories/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.loadRelatedData(accessToken, categoryId)
      await this.setState({
        object: {
          ...this.state.object,
          ...response.data,
        }
      })
      this.image.imageUrl = this.state.object.icon
    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  loadRelatedData = async (accessToken, categoryId) => {
    const childCategoriesResponse = await axios.get(`/admin/categories?parent=${categoryId}&take=10000&skip=0&orderBy=nameAsc`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    await this.setState({
      categories: childCategoriesResponse.data
    })
  }

  async putData(accessToken, cateoryId, data) {
    try {
      await this.setState({ loading: true })
      this.image.imageUrl = this.image.changed ? await saveImage(accessToken, this.image) : this.image.imageUrl
      const tempData = {
        ...data,
        icon: this.image?.imageUrl,
        parent: null
      }
      await axios.put(`/categories/${cateoryId}`,
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.history.push('/admin/category')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  onImageChange = (base64Image) => {
    this.image.changed = true
    this.image.imageUrl = null
    this.image.base64 = base64Image
  }

  onCategoryDeleted = async (categoryId) => {
    await this.setState(prevState => ({
      categories: prevState.categories.filter(c => c.id !== categoryId)
    }))
  }

  onCategoryCreated = async (category) => {
    await this.setState(prevState => ({
      categories: this.sortCategoriesByName([...prevState.categories, category])
    }))
  }

  onCategoryUpdated= async (category) => {
    await this.setState(prevState => ({
      categories: this.sortCategoriesByName([...prevState.categories.filter(c => c.id !== category.id), category])
    }))
  }

  sortCategoriesByName = (categories) => {
    return categories.sort(function(a, b) {
      const nameA = a?.name.toUpperCase() ?? ''
      const nameB = b?.name.toUpperCase() ?? ''
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      // names must be equal
      return 0
    })
  }

  render = () => {
    const { object, categories } = this.state
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Ändra</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <ImageUpload imageUrl={object.icon} onChange={this.onImageChange} />
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Namn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.name}
                                onChange={(e) => this.handleInput('name', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <CategoryListComponent
                                accessToken={this.props.accessToken}
                                parentCategory={object}
                                categories={categories}
                                onCategoryDeleted={(categoryId) => this.onCategoryDeleted(categoryId)}
                                onCategoryCreated={(category) => this.onCategoryCreated(category)}
                                onCategoryUpdated={(category) => this.onCategoryUpdated(category)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        <Button
                          color="success"
                          type="submit"
                          onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.match.params.categoryId, this.state.object) }}
                        >
                          Spara
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return (
        <EntityLoaderComponent
          loading={this.state.loading}
          entityName="kategorin"
          errors={this.state.errors}
          notFound={this.state.notFound} />
      )
    }
  }
}

export default withRouter(CategoryEdit)
