import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import Header from '../../../components/Headers/Header.js'
import { ErrorSummary, resolveError } from '../../../components/Errors/ErrorSummary'
import { Select, Popconfirm, message } from 'antd'
import 'antd/dist/antd.css'
import EntityLoaderComponent from '../../../components/Loader/EntityLoaderComponent'

const arrayOfBadWords = [
  'djävlar anamma',
  'anus',
  'blatte',
  'bög',
  'bögjävel',
  'dildo',
  'fan',
  'fanken',
  'fan också',
  'farao',
  'fasiken',
  'fitta',
  'fy fabian',
  'fy fan',
  'fy farao',
  'fy fasiken',
  'fy skjuttsingen',
  'förbaskad',
  'förjävla',
  'Hitler',
  'helvete',
  'hora',
  'horunge',
  'i helvete',
  'jädra',
  'jäkla',
  'jävla',
  'jävlar',
  'jävlar anamma',
  'kuk',
  'kuken',
  'kukollon',
  'miffo',
  'massagestav',
  'mög',
  'mutta',
  'nazist',
  'neger',
  'ollon',
  'penis',
  'röv',
  'rövhål',
  'satan',
  'sjutton också',
  'sju tusan',
  'skit',
  'svarting',
  'tusan',
  'vafan',
  'va sjutton',
  'åt fanders',
  'åt helvete'
]

class AdEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: null,
      assets: null,
      foundBadWords: false,
      dismissModal: false,
      dismissReason: '',
      errors: null,
      loading: true,
      notFound: false
    }

    this.getData = this.getData.bind(this)
    this.putData = this.putData.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('accessToken'))
    if (token) {
      await this.getData(token, this.props.match.params.adId)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  toggleModal = () => {
    this.setState({
      dismissModal: !this.state.dismissModal,
      dismissReason: ''
    })
  }

  async getData(accessToken, adId) {
    try {
      await this.setState({ loading: true })
      const response = await axios.get(`/ads/${adId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })

      var filter = require('leo-profanity')
      filter.loadDictionary('en')
      filter.add(arrayOfBadWords)

      const wordScannerTitle = filter.check(response.data.title)
      const wordScannerDescription = filter.check(response.data.description)

      await this.setState({
        object: {
          ...this.state.object,
          ...response.data,
        },
        foundBadWords: wordScannerTitle ? true : wordScannerDescription
      })
    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  async putData(accessToken, adId, type) {
    try {
      await this.setState({ loading: true })
      if (type === 'publish') {
        await axios.put(`/ads/${adId}/status/published`,
          { },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        this.props.history.push('/admin/ad')
        window.location.reload()
      } else if (type === 'dismiss') {
        await axios.put(`/ads/${adId}/status/reviewfailed`,
          { reason: this.state.dismissReason },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        this.props.history.push('/admin/ad')
        window.location.reload()
      } else if (type === 'remove') {
        await axios.put(`/ads/${adId}/status/removed`,
          { },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        this.props.history.push('/admin/ad')
        window.location.reload()
      } else {
        message.warn(`Action '${type}' is not supported!`)
      }
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  renderLocation = (location) => {
    if(location) {
      const locationParent = location.parent?.name
      return `${locationParent ? locationParent + ', ' : ''}${location.name ?? ''}`
    }
  }

  adIsInReviewState = (ad) => ad?.status?.toLowerCase() === 'inreview' ?? false
  adIsInRemovedState = (ad) => ad?.status?.toLowerCase() === 'removed' ?? false
  adIsSold = (ad) => ad?.status?.toLowerCase() === 'sold' ?? false

  renderStatus (status) {
    switch(status?.toLowerCase() ?? '') {
    case 'reviewfailed':
      return 'Avböjd'
    case 'published':
      return 'Publicerad'
    case 'sold':
      return 'Såld'
    case 'inreview':
      return 'Ogranskad'
    case 'removed':
      return 'Borttagen'
    default:
      return ''
    }
  }

  render = () => {
    const { object } = this.state
    const { Option } = Select
    var moment = require('moment')
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">{this.renderStatus(object.status)} Annons</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <div className="col-12">
                            <label
                              className="form-control-label"
                              htmlFor="input-images"
                            >
                                Uppladdade bilder
                            </label>
                          </div>
                          {object.images.length > 0 ? object.images.map((item, index) => (
                            <React.Fragment key={index}>
                              <div className="col">
                                <div className="mb-3">
                                  <img className="main-content-img" alt="Bild" src={item.uri} />
                                </div>
                              </div>
                            </React.Fragment>
                          )) : null}
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                              >
                                Titel
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-title"
                                type="text"
                                value={object.title}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-desc"
                              >
                                Beskrivning
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-desc"
                                rows="7"
                                value={object.description}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-card"
                              >
                                Såld av
                              </label>
                              <Card style={{ backgroundColor: 'inherit'}}>
                                <CardBody>
                                  <Row>
                                    <Col lg="3">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-name"
                                        >
                                          Förnamn
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="input-name"
                                          type="text"
                                          value={object.soldBy.firstName}
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-email"
                                        >
                                          E-post
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="input-email"
                                          type="text"
                                          value={object.soldBy.email}
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                      <FormGroup>
                                        <label
                                          className="form-control-label"
                                          htmlFor="input-phoneNumber"
                                        >
                                          Telefonnummer
                                        </label>
                                        <Input
                                          className="form-control-alternative"
                                          id="input-phoneNumber"
                                          type="text"
                                          value={object.soldBy.phoneNumber}
                                          disabled={true}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-createdDate"
                              >
                                Skapad datum
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-createdDate"
                                type="text"
                                value={moment(object.created).format('YYYY-MM-DD HH:mm')}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-updatedDate"
                              >
                                Status Uppdaterad datum
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-updatedDate"
                                type="text"
                                value={moment(object.statusUpdated).format('YYYY-MM-DD HH:mm')}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-price"
                              >
                                Pris
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-price"
                                type="number"
                                value={object.askingPrice}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          { this.adIsSold(object) && object.soldAtPrice !== null &&
                            <Col lg="3">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-soldAtPrice"
                                >
                                  Såldes för
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-soldAtPrice"
                                  type="number"
                                  value={object.soldAtPrice}
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          }
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-condition"
                              >
                                Skick
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-condition"
                                type="number"
                                value={object.condition}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-location"
                              >
                                Plats
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-location"
                                type="text"
                                value={this.renderLocation(object.location)}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-category"
                              >
                                Kategori
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-category"
                                type="text"
                                value={object.category?.name ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-brand"
                              >
                                Varumärke
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-brand"
                                type="text"
                                value={object.productBrand ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-model"
                              >
                                Modell
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-model"
                                type="text"
                                value={object.productModel ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-supportingClub"
                              >
                                Klubb
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-supportingClub"
                                type="text"
                                value={object.supportingClub?.name ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="3">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-clubPercent"
                              >
                                Klubb procent
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-clubPercent"
                                type="number"
                                value={object.supportingClubPercentage}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-team-name"
                              >
                                Lagnamn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-team-name"
                                type="text"
                                value={object.soldBy?.teamName ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <div className="pl-lg-4 mb-4">
                        {object.category && object.supportingClubPercentage >= 10 && object.description && object.title ? (
                          <Alert
                            color="success"
                          >
                          Annonsen har rätt information
                          </Alert>
                        ) :
                          (
                            <Alert
                              color="warning"
                            >
                          Något är fel.
                            </Alert>
                          )}
                      </div>
                      <div className="pl-lg-4 mb-4">
                        {this.state.foundBadWords ? (
                          <Alert
                            color="danger"
                          >
                          Titel och beskrivning innehåller lämpliga ord.
                          </Alert>
                        ) :
                          (
                            <Alert
                              color="success"
                            >
                          Titel och beskrivning innehåller inga lämpliga ord.
                            </Alert>
                          )}
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        { this.adIsInReviewState(object) ?
                          <>
                            <Button
                              color="success"
                              type="submit"
                              onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.match.params.adId, 'publish') }}
                            >
                              Publicera
                            </Button>
                            <Button
                              color="danger"
                              type="submit"
                              onClick={(e) => { e.preventDefault(); this.toggleModal() }}
                            >
                              Avböj
                            </Button>
                          </>
                          : !this.adIsInRemovedState(object) &&
                          <Popconfirm
                            placement="topLeft" title='Är du säker på att du vill ta bort annonsen?'
                            onConfirm={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.match.params.adId, 'remove') }}
                            okText="Ja" cancelText="Nej">
                            <Button color="danger" type="submit" >
                              Ta bort
                            </Button>
                          </Popconfirm>
                        }
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Modal isOpen={this.state.dismissModal} toggle={() => this.toggleModal()} style={{width: '20rem'}}>
              <ModalHeader toggle={() => this.toggleModal()}>Orsak till avböjan</ModalHeader>
              <ModalBody>
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-category"
                  >
                                Orsak:
                  </label>
                  <Select
                    value={this.state.dismissReason}
                    className="form-control-alternative form-control"
                    onChange={(value) => this.setState({
                      dismissReason: value
                    })}
                  >
                    <Option value="picture">Olämplig bild</Option>
                    <Option value="text">Olämplig titel eller beskrivning</Option>
                    <Option value="tooHighPrice">För högt pris</Option>
                    <Option value="wrongCategory">Fel kategori vald</Option>
                    <Option value="other">Övrigt</Option>
                  </Select>
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={(e) => { e.preventDefault(); this.toggleModal() }}>Tillbaka</Button>{' '}
                <Button color="danger" disabled={this.state.dismissReason ? false : true} onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.match.params.adId, 'dismiss') }}>Avböj</Button>
              </ModalFooter>
            </Modal>
          </Container>
        </>
      )
    } else {
      return (
        <EntityLoaderComponent
          loading={this.state.loading}
          entityName="annonsen"
          errors={this.state.errors}
          notFound={this.state.notFound} />
      )
    }
  }
}

export default withRouter(AdEdit)
