import React from 'react'
import { withRouter } from 'react-router-dom'
import ClubListComponent from '../../../components/Clubs/ClubList'

class ClubStatisticsList extends React.Component {

  render = () => {
    return <ClubListComponent {...this.props} pageSize={15} navigateUrl='/admin/statistics' menuText='Se statistics' storageKey='statistics-club' />
  }
}

export default withRouter(ClubStatisticsList)
