import { isNil } from 'lodash'

const isNullOrWhitespace = (input) => {
  if (isNil(input)) return true

  if (typeof input != 'string') throw new Error('ArgumentException: input is not a valid string')

  return input.replace(/\s/g, '').length < 1
}

const isValidUrl = (input) => {
  let url
  try {
    if(isNullOrWhitespace(input)) return false
    url = new URL(input)
  } catch(e) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export { isNullOrWhitespace, isValidUrl }
