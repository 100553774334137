import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import DashboardHeaderCard from './DashboardHeaderCard'
import GrowthRatePeriodSelector from '../Stats/GrowthRatePeriodSelector'

const DashboardHeader = (props) => {

  const periodSelectorVisible = process.env.REACT_APP_SHOW_GROWTHRATE_PERIOD_SELECTOR === 'true'

  return (
    <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
      <Container fluid>
        <div className="header-body">
          <Row>
            <Col lg="6" xl="3">
              <DashboardHeaderCard
                title="Nya användare"
                value={props.stats.usersStats.numberOfNewUsers} unit="st"
                color="bg-warning" icon="fa-users"
                footerStats={{
                  growthStats: props.stats.usersStats.growthStats,
                  unit: '%',
                  description:'Sen sista #period# dagar',
                  noValDescription: 'Behöver ett värde från sista #period# dagar.',
                }}
              />
            </Col>
            <Col lg="6" xl="3">
              <DashboardHeaderCard
                title="Sålda annonser"
                value={props.stats.adsStats.totalSoldAdsRevenue} unit="kr"
                color="bg-yellow" icon="fa-chart-pie"
                footerStats={{
                  growthStats: props.stats.adsStats.revenueGrowthStats,
                  unit: '%',
                  description:'Sen sista #period# dagar',
                  noValDescription: 'Behöver ett värde från sista #period# dagar.',
                }}
              />
            </Col>
            <Col lg="6" xl="3">
              <DashboardHeaderCard
                title="Antal annonser"
                value={props.stats.adsStats.totalNumberOfAds} unit="st"
                color="bg-info" icon="fa-percent"
                footerStats={{
                  growthStats: props.stats.adsStats.growthStats,
                  unit: '%',
                  description:'Sen sista #period# dagar',
                  noValDescription: 'Behöver ett värde från sista #period# dagar.',
                }}
              />
            </Col>
            <Col lg="6" xl="3">
              <DashboardHeaderCard
                title="Antal klubbar"
                value={props.stats.clubsStats.totalNumberOfClubs} unit="st"
                color="bg-info" icon="fa-percent"
                footerStats={{
                  growthStats: props.stats.clubsStats.growthStats,
                  unit: '%',
                  description:'Sen sista #period# dagar',
                  noValDescription: 'Behöver ett värde från sista #period# dagar.',
                }}
              />
            </Col>
          </Row>
          <GrowthRatePeriodSelector visible={periodSelectorVisible} onPeriodChanged={props.onPeriodChanged} />
        </div>
      </Container>
    </div>
  )
}

export default DashboardHeader
