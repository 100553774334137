import React from 'react'
import moment from 'moment'
import { PropTypes } from 'prop-types'

const GrowthRateStatsDetails = (props) => {
  if(!props.growthStats) {
    return <span className="mr-2">No data</span>
  }
  const { startDate, period, startingValue, endingValue } = props.growthStats

  const startingFrom =  moment(startDate).subtract((period * 2) - 1, 'days')
  const startingTo = moment(startDate).subtract(period, 'days')
  const endingFrom = moment(startDate).subtract(period - 1, 'days')
  const endingTo = moment(startDate)

  return (
    <div>
      <p className="mt-0 mb-1 text-muted text-sm">
        <span className="text-nowrap">{startingFrom.format('YYYY-MM-DD')} / {startingTo.format('YYYY-MM-DD')}</span>
        &nbsp;
        <span className="mr-2">
          ({startingValue}{props.unit})
        </span>
      </p>
      <p className="mt-0 mb-1 text-muted text-sm">
        <span className="text-nowrap">{endingFrom.format('YYYY-MM-DD')} / {endingTo.format('YYYY-MM-DD')}</span>
        &nbsp;
        <span className="mr-2">
          ({endingValue}{props.unit})
        </span>
      </p>
    </div>
  )
}

GrowthRateStatsDetails.defaultProps = {
  growthStats: null,
  unit: ''
}
GrowthRateStatsDetails.propTypes = {
  growthStats: PropTypes.shape({
    startDate: PropTypes.string.isRequired, //PropTypes.instanceOf(Date)
    period: PropTypes.number.isRequired,
    startingValue: PropTypes.number.isRequired,
    endingValue: PropTypes.number.isRequired
  }),
  unit: PropTypes.string
}

export default GrowthRateStatsDetails
