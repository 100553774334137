import React from 'react'
import { Upload, message, Image } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import {
  FormGroup,
  Row,
  Col,
  Button
} from 'reactstrap'

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

class ImageUpload extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      imageUrl: props.imageUrl,
      loading: false,
      previewVisible: false,
      label: this.props.label ?? 'Symbol',
      file: null
    }
  }

  saveTempFile = async (file) => {
    this.setState({ loading: true })
    try {
      var base64Image = await toBase64(file)
      this.setState({
        imageUrl: base64Image
      })
      if(this.props.onChange) { this.props.onChange(base64Image) }
    } catch (error) {
      message.error('There was an error selecting the image')
    } finally {
      this.setState({ loading: false })
    }
  }

  beforeUpload = (file) => {
    if (!file.type.startsWith('image/')) {
      message.error('You can only upload image files.')
      return false
    }
    const isLt2M = (file.size / 1024 / 1024) < 2
    if (!isLt2M) {
      message.error('Image must smaller than 2MB')
      return false
    }

    // Dont await as antd tries to post before the false return
    this.saveTempFile(file)

    return false
  }

  clearImage = () => {
    if(this.props.onChange) { this.props.onChange(null) }
    this.setState({
      imageUrl: null,
      base64ImageUrl: null
    })
  }

  previewImage = (value) => {
    this.setState({ previewVisible: value})
  }

  render() {
    const { loading, imageUrl, previewVisible } = this.state
    const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Välj</div>
      </div>
    )
    return (
      <>
        <Row>
          <Col lg="12">
            <FormGroup>
              <label
                className="form-control-label"
                htmlFor="input-name"
              >
                {this.state.label}
              </label>
              <Upload
                name="symbol"
                listType="picture-card"
                showUploadList={false}
                accept="image/*"
                beforeUpload={this.beforeUpload}
              >
                {imageUrl ? <img src={imageUrl} style={{ width: '100%', height: '100%', objectFit: 'contain' }} alt="Bild" /> : uploadButton }
              </Upload>
            </FormGroup>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col lg="12">
            <div className="d-flex align-content-start justify-content-start" style={{marginBottom: 10}}>
              <div>
                {imageUrl &&
                    <Button
                      color="primary"
                      onClick={this.clearImage}
                      size="sm"
                    >
                      Radera
                    </Button>
                }
              </div>
              <div>
                {imageUrl &&
                  <>
                    <Button
                      color="success"
                      onClick={this.previewImage}
                      size="sm"
                      style={{ marginLeft: 10 }}
                    >
                      Förhandsvisa
                    </Button>
                    <Image
                      style={{ display: 'none' }}
                      src={imageUrl}
                      preview={{
                        visible: previewVisible,
                        src: imageUrl,
                        onVisibleChange: value => {
                          this.previewImage(value)
                        },
                      }}
                    />
                  </>
                }
              </div>
            </div>
          </Col>
        </Row>
      </>
    )
  }
}

export default ImageUpload
