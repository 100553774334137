import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { refreshToken, logOut } from '../utils/auth'

const plainAxios = axios.create({ baseURL: `${process.env.REACT_APP_API_DOMAIN}/v1` })

axios.defaults.baseURL = `${process.env.REACT_APP_API_DOMAIN}/v1`

const refreshAuthLogic = failedRequest => refreshToken().then(token => {
  localStorage.setItem('accessToken', JSON.stringify(token))
  failedRequest.response.config.headers['Authorization'] = 'Bearer ' + token
  return Promise.resolve()
}).catch(() => {
  logOut()
    .catch(() => {
      // As this isn't a controlled event, if fb auth signOut fails, we fallback to the old way
      sessionStorage.clear()
      localStorage.clear()
    })
    .finally(() => {
      window.location.reload()
    })
})

createAuthRefreshInterceptor(axios, refreshAuthLogic)

export { axios, plainAxios }
