import React from 'react'
import { axios } from '../utils/api-config'
import Chart from 'chart.js'
import { Line, Bar } from 'react-chartjs-2'
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap'
import {
  chartOptions,
  parseOptions,
} from '../variables/charts.js'
import DashboardHeader from '../components/Headers/DashboardHeader.js'
import moment from 'moment'
import 'moment/locale/sv'
import { resolveError } from '../components/Errors/ErrorSummary'

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeNav: 1,
      usersStats: {
        numberOfNewUsers: 0,
        growthStats: null
      },
      adsStats: {
        totalSoldAdsRevenue: 0,
        totalNumberOfAds: 0,
        revenueGrowthStats: null,
        growthStats: null,
      },
      clubsStats: {
        totalNumberOfClubs: 0,
        growthStats: null
      },
      // TODO: Graphs
      activeEvents: {
        data: [],
        labels: []
      },
      totalIncome: {
        data: [],
        labels: []
      }
    }
  }

  async componentDidMount() {
    try {
      const token = JSON.parse(localStorage.getItem('accessToken'))
      if (token) {
        moment.localeData('sv')
        const now = moment.utc().format()
        const growthPeriod = process.env.REACT_APP_DEFAULT_GROWTHRATE_PERIOD ?? 30
        await this.loadStats(token, now, growthPeriod)
      }
    } catch (error) {
      resolveError(error)
    }
  }

  loadStats = async (token, now, growthPeriod) => {
    const stats = await this.fetchStats(token, now, growthPeriod)

    await this.setState({
      usersStats: {
        ...this.state.usersStats,
        numberOfNewUsers: stats.usersStats.createdGrowthRate.endingValue,
        growthStats: stats.usersStats.createdGrowthRate
      },
      adsStats: {
        ...this.adsStats,
        totalSoldAdsRevenue: stats.adsStats.totalSoldRevenue,
        totalNumberOfAds: stats.adsStats.totalAmount,
        revenueGrowthStats: stats.adsStats.revenueGrowthRate,
        growthStats: stats.adsStats.createdGrowthRate
      },
      clubsStats: {
        ...this.clubsStats,
        totalNumberOfClubs: stats.clubsStats.totalAmount,
        growthStats: stats.clubsStats.createdGrowthRate
      },
    })
  }

  fetchStats = async (accessToken, sinceCreated, growthPeriod) => {
    let endpoints = [
      `/admin/stats/dashboard/users?&startDate=${sinceCreated}&period=${growthPeriod}`,
      `/admin/stats/dashboard/ads?&startDate=${sinceCreated}&period=${growthPeriod}`,
      `/admin/stats/dashboard/clubs?&startDate=${sinceCreated}&period=${growthPeriod}`,
    ]

    const {data: usersStats}  = await axios.get(endpoints[0], { headers: { Authorization: `Bearer ${accessToken}`} })
    const {data: adsStats}    = await axios.get(endpoints[1], { headers: { Authorization: `Bearer ${accessToken}`} })
    const {data: clubsStats}  = await axios.get(endpoints[2], { headers: { Authorization: `Bearer ${accessToken}`} })


    return { usersStats, adsStats, clubsStats }
  }

  render() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions())
    }

    const chartExample2 = {
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (value) {
                  if (!(value % 10)) {
                    return value
                  }
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (item, data) {
              var label = data.datasets[item.datasetIndex].label || ''
              var yLabel = item.yLabel
              var content = ''
              if (data.datasets.length > 1) {
                content += label
              }
              content += yLabel
              return content
            },
          },
        },
      },
      data: {
        // labels: this.state.activeEvents.labels,
        labels: ['Jan', 'Feb', 'Mars', 'April', 'Maj'],
        datasets: [
          {
            // data: this.state.activeEvents.data,
            data: [0, 0, 0, 0, 0],
            maxBarThickness: 10,
          },
        ],
      },
    }

    const colors = {
      gray: {
        100: '#f6f9fc',
        200: '#e9ecef',
        300: '#dee2e6',
        400: '#ced4da',
        500: '#adb5bd',
        600: '#8898aa',
        700: '#525f7f',
        800: '#32325d',
        900: '#212529',
      },
      theme: {
        default: '#172b4d',
        primary: '#DC353C',
        secondary: '#f4f5f7',
        info: '#11cdef',
        success: '#2dce89',
        danger: '#f5365c',
        warning: '#fb6340',
      },
      black: '#12263F',
      white: '#FFFFFF',
      transparent: 'transparent',
    }

    const chartExample1 = {
      options: {
        scales: {
          yAxes: [
            {
              gridLines: {
                color: colors.gray[900],
                zeroLineColor: colors.gray[900],
              },
              ticks: {
                callback: function (value) {
                  if (!(value % 10)) {
                    return value + ' st'
                  }
                },
              },
            },
          ],
        },
        tooltips: {
          callbacks: {
            label: function (item, data) {
              var label = data.datasets[item.datasetIndex].label || ''
              var yLabel = item.yLabel
              var content = ''

              if (data.datasets.length > 1) {
                content += label
              }

              content += yLabel + ' st'
              return content
            },
          },
        },
      },
      data1: () => {
        return {
          // labels: this.state.totalIncome.labels,
          labels: ['Jan', 'Feb', 'Mars', 'April', 'Maj', 'Juni', 'Juli'],
          datasets: [
            {
              // data: this.state.totalIncome.data,
              data: [0, 0, 0, 0, 0, 0, 0]
            },
          ],
        }
      },
    }

    return (
      <>
        <DashboardHeader stats={this.state} onPeriodChanged={this.loadStats} />
        <Container className="mt--7" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="8">
              <Card className="bg-white shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                        Översikt
                      </h6>
                      <h2 className=" mb-0">Antal annonser</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Line
                      data={chartExample1['data1']}
                      options={chartExample1.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="shadow">
                <CardHeader className="bg-transparent">
                  <Row className="align-items-center">
                    <div className="col">
                      <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Översikt
                      </h6>
                      <h2 className="mb-0">Antal sålda annonser</h2>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart">
                    <Bar
                      data={chartExample2.data}
                      options={chartExample2.options}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default Index
