import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from 'reactstrap'
import 'antd/dist/antd.css'
import { TreeSelect, Popconfirm, Switch } from 'antd'
import Header from '../../../components/Headers/Header.js'
import ImageUpload from '../../../components/ImageUpload/ImageUpload'
import { saveImage } from '../../../utils/ImageHelper'
import { ErrorSummary, resolveError } from '../../../components/Errors/ErrorSummary'
import EntityLoaderComponent from '../../../components/Loader/EntityLoaderComponent'
import QRCode from 'react-qr-code'
import toImg from 'react-svg-to-image'

class ClubEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: null,
      errors: null,
      loading: true,
      notFound: false,
      categories: [],
      categoriesValue: [],
      locations: [],
      locationsValue: null,
      branchLink: null,
    }

    this.getData = this.getData.bind(this)
    this.putData = this.putData.bind(this)
    this.handleInput = this.handleInput.bind(this)
    this.shareLink = this.shareLink.bind(this)

    this.image = {
      changed: false,
      base64: null,
      imageUrl: null
    }
  }

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('accessToken'))
    if (token) {
      await this.getData(token, this.props.match.params.clubId)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  async getData(accessToken, clubId) {
    try {
      await this.setState({
        loading: true
      })
      await this.loadRelatedData(accessToken)
      const response = await axios.get(`/admin/clubs/${clubId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      await this.setState({
        object: {
          ...this.state.object,
          ...response.data,
        },
        categoriesValue: response.data.categories?.map(c => c.id) ?? [],
        locationsValue: response.data.location?.id
      })
      this.image.imageUrl = this.state.object.logo
    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  loadRelatedData = async (accessToken) => {
    const categoriesResponse = await axios.get('/admin/categories/tree', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    await this.setState({
      categories: categoriesResponse.data
    })
    const locationsResponse = await axios.get('/admin/locations/tree', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    await this.setState({
      locations: locationsResponse.data
    })
  }

  async putData(accessToken, clubId, data) {
    try {
      await this.setState({
        loading: true
      })
      this.image.imageUrl = this.image.changed ? await saveImage(accessToken, this.image) : this.image.imageUrl
      const tempData = {
        ...data,
        logo: this.image?.imageUrl,
        categories: this.state.categoriesValue?.map(guid => ({ id: guid })) ?? [],
        location: this.state.locationsValue ? { id: this.state.locationsValue } : null
      }
      await axios.put(`/clubs/${clubId}`,
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.history.push('/admin/club')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  publishClub = async (accessToken, clubId) => {
    try {
      await this.setState({ loading: true })
      await axios.put(`/admin/clubs/${clubId}/publish`, {}, { headers: { Authorization: `Bearer ${accessToken}` } })
      this.props.history.push('/admin/club')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  categoryTreeData = () => {
    return this.state.categories.map(cat => ({
      id: cat.id,
      pId: cat.parentId,
      title: cat.name,
      value: cat.id
    }))
  }

  onCategoryTreeChange = (value) => {
    this.setState({ categoriesValue: value })
  };

  locationTreeData = () => {
    return this.state.locations.filter(loc => loc.parentId !== null).map(loc => ({
      id: loc.id,
      pId: loc.parentId,
      title: loc.name,
      value: loc.id
    }))
  }

  onLocationTreeChange = (value) => {
    this.setState({ locationsValue: value })
  };

  onImageChange = (base64Image) => {
    this.image.changed = true
    this.image.imageUrl = null
    this.image.base64 = base64Image
  }

  async shareLink(object) {
    const branch = window.branch
    const linkData = {
      campaign: object.name,
      channel: 'onboarding',
      feature: 'share',
      data: {
        redirectType: 'OnboardingClub',
        redirectId: object.id,
        '$og_title': object.name
      }
    }
    const that = this
    branch.link(linkData, function(err, link) {
      that.setState({
        branchLink: link
      })
    })
  }

  render = () => {
    const { object, categoriesValue, locationsValue } = this.state
    const { SHOW_PARENT } = TreeSelect

    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Ändra</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <ImageUpload imageUrl={object.logo} onChange={this.onImageChange} />
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Namn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.name}
                                onChange={(e) => this.handleInput('name', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Beskrivning
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-name"
                                rows="7"
                                value={object.description}
                                onChange={(e) => this.handleInput('description', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Swish-nummer
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.swishPhoneNumber}
                                onChange={(e) => this.handleInput('swishPhoneNumber', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-free-user-sub"
                              >
                                Ge ett gratis medlemskap
                              </label>
                              <Switch
                                id="input-free-user-sub"
                                checkedChildren="Ja"
                                unCheckedChildren="Nej"
                                checked={object.freeUserSubscription}
                                onChange={(checked) => this.handleInput('freeUserSubscription', checked)} />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Kategorier
                              </label>
                              <TreeSelect
                                value={categoriesValue}
                                treeData={this.categoryTreeData()}
                                treeDataSimpleMode={{
                                  id: 'id',
                                  pId: 'pId',
                                }}
                                treeCheckable
                                showCheckedStrategy={SHOW_PARENT}
                                style={{ width: '100%' }}
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="Sök efter kategori..."
                                multiple
                                filterTreeNode={(search, item) => {
                                  return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                                }}
                                onChange={this.onCategoryTreeChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6" sm="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Plats
                              </label>
                              <TreeSelect
                                value={locationsValue}
                                treeData={this.locationTreeData()}
                                treeDataSimpleMode={{
                                  id: 'id',
                                  pId: 'pId',
                                }}
                                style={{ width: '100%' }}
                                allowClear
                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                placeholder="Välj plats..."
                                filterTreeNode={(search, item) => {
                                  return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0
                                }}
                                onChange={this.onLocationTreeChange}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        {this.state.object.id && (
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-branchLink"
                                >
                                Onboarding länk
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  id="input-branchLink"
                                  type="text"
                                  disabled
                                  value={this.state.branchLink}
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="3" className='mt-2'>
                              <Button
                                color="success"
                                className='mt-4'
                                onClick={() => this.shareLink(this.state.object)}
                              >
                          Generera klubblänk
                              </Button>
                            </Col>
                            {this.state.branchLink && (
                              <Col lg="3">
                                <QRCode id="qr-image" size={128} bgColor={'#fff'} fgColor={'#000'} value={this.state.branchLink} />
                                <Button
                                  color="success"
                                  className='mt-4'
                                  onClick={() => toImg('#qr-image', `qr-kod ${object.name}`, {
                                    scale: 3
                                  })}
                                >
                          Ladda ner QR-kod
                                </Button>
                              </Col>
                            )}
                          </Row>
                        )}
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        <Button
                          color="success"
                          type="submit"
                          onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.match.params.clubId, this.state.object) }}
                        >
                          Spara
                        </Button>
                        {object.draft &&
                          <Popconfirm
                            placement="topLeft" title='Är du säker på att du vill publicera Klubben? '
                            onConfirm={(e) => { e.preventDefault(); this.publishClub(this.props.accessToken, this.props.match.params.clubId) }}
                            okText="Ja" cancelText="Nej"
                          >
                            <Button color="success" type="submit" >
                              Publicera
                            </Button>
                          </Popconfirm>
                        }
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return (
        <EntityLoaderComponent
          loading={this.state.loading}
          entityName="klubben"
          errors={this.state.errors}
          notFound={this.state.notFound} />
      )
    }
  };
}

export default withRouter(ClubEdit)
