import React from 'react'
import { axios } from '../../utils/api-config'
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Button,
  Table,
  Row,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import { resolveError } from '../Errors/ErrorSummary'
import LocationCreateComponent from './LocationCreate'
import LocationEditComponent from './LocationEdit'

class LocationListComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalCreateVisible: false,
      modalEditVisible: false,
      selectedLocationId: null
    }

    this.delObject = this.delObject.bind(this)
  }

  async delObject(accessToken, locationId) {
    try {
      await axios.delete(`/locations/${locationId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.onLocationDeleted(locationId)
    } catch (error) {
      resolveError(error)
    }
  }

  navigate = async (item) => {
    await this.setState({
      selectedLocationId: item.id
    })
    this.toggleModalEdit()
  }

  toggleModalCreate = () => {
    this.setState({
      modalCreateVisible: !this.state.modalCreateVisible
    })
  }

  toggleModalEdit = () => {
    this.setState({
      modalEditVisible: !this.state.modalEditVisible
    })
  }

  render = () => {
    return (
      <>
        <Card className="shadow">
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Underplatser</h3>
              </Col>
              <Col className="text-right" xs="4">
                <Button
                  color="primary"
                  size="sm"
                  onClick={this.toggleModalCreate}
                >
                  Skapa ny
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Namn</th>
                <th scope="col" />
              </tr>
            </thead>
            <tbody>
              {this.props.locations?.length > 0 ?? false
                ? this.props.locations.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tr>
                        <td onClick={() => { this.navigate(item) }}>
                          <span className="mb-0 text-sm">
                            {item.name}
                          </span>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu
                              className="dropdown-menu-arrow"
                              right
                            >
                              <DropdownItem
                                onClick={(e) => { e.preventDefault(); this.navigate(item) }}
                              >
                                Ändra
                              </DropdownItem>
                              <DropdownItem
                                href="#"
                                onClick={(e) => { e.preventDefault(); this.delObject(this.props.accessToken, item.id) }}
                              >
                                Radera
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                })
                : null}
            </tbody>
          </Table>
        </Card>
        <Modal isOpen={this.state.modalCreateVisible} toggle={this.toggleModalCreate} backdrop="static" keyboard={false} unmountOnClose={true}>
          <ModalBody>
            <LocationCreateComponent
              accessToken={this.props.accessToken}
              parentLocation={this.props.parentLocation}
              onLocationCreated={(location) => {
                this.toggleModalCreate()
                this.props.onLocationCreated(location)}
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggleModalCreate}>Tillbaka</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalEditVisible} toggle={this.toggleModalEdit} backdrop="static" keyboard={false} unmountOnClose={true}>
          <ModalBody>
            <LocationEditComponent
              accessToken={this.props.accessToken}
              locationId={this.state.selectedLocationId}
              onLocationUpdated={(location) => {
                this.toggleModalEdit()
                this.props.onLocationUpdated(location)}
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggleModalEdit}>Tillbaka</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  };
}

export default LocationListComponent
