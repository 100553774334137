import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  FormText
} from 'reactstrap'
import 'antd/dist/antd.css'
import Header from '../../../components/Headers/Header.js'
import { ErrorSummary, resolveError } from '../../../components/Errors/ErrorSummary'
import { latitudeIsValid, longitudeIsValid } from '../../../utils/CoordinatesHelper'

class LocationCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: {
        name: '',
        coordinates: {
          latitude: '',
          longitude: '',
        },
        parent: null
      },
      errors: null,
      loading: false,
      longitudeTouched: false,
      latitudeTouched: false
    }

    this.postData = this.postData.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  async postData(accessToken, data) {
    try {
      await this.setState({
        loading: true
      })
      const tempData = {
        ...data
      }
      await axios.post('/locations',
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.history.push('/admin/location')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    if(key === 'latitude' || key === 'longitude')
    {
      let { coordinates } = this.state.object
      coordinates[key] = value
      await this.setState(prevState => ({
        object: {
          ...prevState.object,
          coordinates
        }
      }))
    } else {
      await this.setState(prevState => ({
        object: {
          ...prevState.object,
          [key]: value
        }
      }))
    }
  }

  frmIsValid = ({ name, coordinates }) => {
    return name.length > 0 
            && latitudeIsValid(coordinates) 
            && longitudeIsValid(coordinates)
  }

  render = () => {
    const { object } = this.state
    const isValid = this.frmIsValid({...this.state.object })
    const { latitudeTouched, longitudeTouched } = this.state
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Skapa ny</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Namn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.name}
                                onChange={(e) => this.handleInput('name', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-lat"
                              >
                                Latitude
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-lat"
                                type="text"
                                value={object.coordinates.latitude}
                                onChange={(e) => this.handleInput('latitude', e.target.value)}
                                onBlur={() => !latitudeTouched && this.setState({ latitudeTouched: true })}
                              />
                              <FormText color="danger" style={{ display: latitudeTouched && !latitudeIsValid(object.coordinates) ? 'block' : 'none' }}>
                                * Latitude måste vara en giltig siffra
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-long"
                              >
                                Longitude
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-long"
                                type="text"
                                value={object.coordinates.longitude}
                                onChange={(e) => this.handleInput('longitude', e.target.value)}
                                onBlur={() => !longitudeTouched && this.setState({ longitudeTouched: true })}
                              />
                              <FormText color="danger" style={{ display: longitudeTouched && !longitudeIsValid(object.coordinates) ? 'block' : 'none' }}>
                                * Longitude måste vara en giltig siffra
                              </FormText>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        {!this.state.loading
                          ?
                          (<Button
                            color="success"
                            type="submit"
                            onClick={(e) => { e.preventDefault(); this.postData(this.props.accessToken, this.state.object) }}
                            disabled={!isValid}
                          >
                            Skapa
                          </Button>)
                          :
                          (<Spinner color='danger' type='border' style={{marginLeft: '25px', marginTop: '5px'}}></Spinner>)
                        }
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return null
    }
  };
}

export default withRouter(LocationCreate)
