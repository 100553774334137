import { Breadcrumb } from 'antd'
import React from 'react'
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap'
import { logOut } from '../../utils/auth'
import { resolveError } from '../Errors/ErrorSummary'

class AdminNavbar extends React.Component {
  async logout() {
    try {
      await logOut()
      this.props.history.push('/auth/login')
      window.location.reload()
    } catch {
      resolveError(new Error('An unexpected error ocurred signing out. Please try again.'))
    }
  }

  render() {
    var gravatar = require('gravatar')
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Breadcrumb className='white-text-breadcrumb'>
              <Breadcrumb.Item>
                Hem
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {this.props.brandText.name}
              </Breadcrumb.Item>
            </Breadcrumb>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img src={this.props.user ? this.props.user.email ? gravatar.url(this.props.user.email) : require('../../assets/img/picture.svg') : require('../../assets/img/picture.svg')} alt="Avatar" />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.props.user ? this.props.user.firstName ? (this.props.user.firstName + ' ' + this.props.user.lastName) : 'Hej lagspelare' : 'Hej lagspelare'}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem onClick={() => this.logout()}>
                    <i className="ni ni-user-run" />
                    <span>Logga ut</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    )
  }
}

export default AdminNavbar
