import React from 'react'
import { axios } from '../../utils/api-config'
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  FormText
} from 'reactstrap'
import 'antd/dist/antd.css'
import Header from '../Headers/Header.js'
import ImageUpload from '../ImageUpload/ImageUpload'
import { saveImage } from '../../utils/ImageHelper'
import { ErrorSummary, resolveError } from '../Errors/ErrorSummary'
import { isNullOrWhitespace, isValidUrl } from '../../utils/validators'

class CouponCreateComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: {
        title: '',
        description: '',
        terms: '',
        thumbnail: '',
        image: '',
        code: '',
        link: '',
        owner: { id: props.partnerId },
        linkTouched: false,
      },
      errors: null,
      loading: false,
      selectedImage: false
    }

    this.postData = this.postData.bind(this)
    this.handleInput = this.handleInput.bind(this)

    this.image = {
      base64: null,
      imageUrl: null
    }
    this.thumbnail = {
      base64: null,
      imageUrl: null
    }
  }

  async postData(accessToken, data) {
    try {
      await this.setState({ loading: true })
      this.image.imageUrl = await saveImage(accessToken, this.image)
      this.thumbnail.imageUrl = await saveImage(accessToken, this.thumbnail)
      const tempData = {
        ...data,
        image: this.image?.imageUrl,
        thumbnail: this.thumbnail?.imageUrl,
      }
      const couponResponse = await axios.post('/coupons',
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.onCouponCreated({...couponResponse.data, consumedTimes: 0 })
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    } finally {
      await this.setState({ loading: false })
    }
  }

  async handleInput(key, value) {
    await this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: value
      }
    }))
  }

  onImageChange = (key, base64Image) => {
    if(key === 'thumbnail') {
      this.thumbnail.imageUrl = null
      this.thumbnail.base64 = base64Image
    }
    if(key === 'image') {
      this.image.imageUrl = null
      this.image.base64 = base64Image
      this.setState({
        selectedImage: base64Image !== null
      })
    }
  }

  frmIsValid = ({ description, link, code, selectedImage }) => {
    return !isNullOrWhitespace(description)
           && (isNullOrWhitespace(link) || isValidUrl(link))
           && (selectedImage || !isNullOrWhitespace(code) || !isNullOrWhitespace(link))
  }

  render = () => {
    const { object, selectedImage } = this.state
    const isValid = this.frmIsValid({...this.state.object, selectedImage: this.state.selectedImage })
    if (object) {
      return (
        <>
          <Header redColor={true} text="SKAPA NY KUPONG" />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-title"
                              >
                                Titel
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-title"
                                type="text"
                                value={object.title}
                                onChange={(e) => this.handleInput('title', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <ImageUpload imageUrl={null} label={'Thumbnail'} onChange={(base64Image) => this.onImageChange('thumbnail', base64Image)} />
                        <Card>
                          <CardBody className="bg-white border-0">
                            <CardTitle>
                              <h3 style={{ fontWeight: 'bold'}}>Alfabetisk kod / QR kod / Länk</h3>
                            </CardTitle>
                            {!selectedImage && !object.link && (
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-code"
                                    >
                                      Alfabetisk kod
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-code"
                                      type="text"
                                      value={object.code}
                                      onChange={(e) => this.handleInput('code', e.target.value)}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                            {!object.code && !object.link && <ImageUpload imageUrl={null} label={'QR kod'} onChange={(base64Image) => this.onImageChange('image', base64Image)} />}
                            {!selectedImage && !object.code && (
                              <Row>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-link"
                                    >
                                      Länk
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-link"
                                      type="text"
                                      value={object.link}
                                      onChange={(e) => this.handleInput('link', e.target.value)}
                                      onBlur={() => object.link && !this.state.linkTouched && this.setState({ linkTouched: true })}
                                    />
                                    <FormText color="danger" style={{ display: object.link && this.state.linkTouched && !isValidUrl(object.link) ? 'block' : 'none' }}>
                                      * Länk måste vara ett giltig url.
                                    </FormText>
                                  </FormGroup>
                                </Col>
                              </Row>
                            )}
                          </CardBody>
                        </Card>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-desc"
                              >
                                Beskrivning
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-desc"
                                rows="7"
                                value={object.description}
                                onChange={(e) => this.handleInput('description', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-terms"
                              >
                                Villkor
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-terms"
                                rows="7"
                                value={object.terms}
                                onChange={(e) => this.handleInput('terms', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        {!this.state.loading
                          ?
                          (<Button
                            color="success"
                            type="submit"
                            disabled={!isValid}
                            onClick={(e) => { e.preventDefault(); this.postData(this.props.accessToken, this.state.object) }}
                          >
                            Skapa
                          </Button>)
                          :
                          (<Spinner color='danger' type='border' style={{marginLeft: '25px', marginTop: '5px'}}></Spinner>)
                        }
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return null
    }
  };
}

export default CouponCreateComponent
