import { axios } from './api-config'

const saveImage = async (accessToken, image) => {
  try {
    if(!(image?.base64)) { return null }
    if(image?.imageUrl) { return image.imageUrl }

    const base64 = image.base64.substr(image.base64.indexOf(',') + 1)
    const mimeType = image.base64.substring(image.base64.indexOf(':') + 1, image.base64.indexOf(';'))

    const response = await axios.post('/images',
      {
        base64EncodedData: base64,
        mimeType: mimeType
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
    if (response.headers.location.includes('https://')) {
      return response.headers.location
    } else {
      return response.headers.location.replace('http://', 'https://')
    }
  } catch(error) {
    const errors = error?.response?.data?.errors ?? error?.response?.data?.detail ?? 'There was an error saving the image'
    throw new Error(errors)
  }
}

export { saveImage }
