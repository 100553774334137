import React from 'react'
import { axios } from '../../utils/api-config'
import { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from 'reactstrap'
import 'antd/dist/antd.css'
import { message, Popconfirm } from 'antd'
import Header from '../Headers/Header.js'
import { resolveError } from '../Errors/ErrorSummary'

const AdChatMessageComponent = ({ accessToken, chatMessage, onMessageDeleted, onUserDeleted, onClosed }) => {
  const [text, setText] = useState(chatMessage.text)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getCompleteChatText = async (accessToken, id) => {
      try {
        setLoading(true)
        const { data: messageText } = await axios.get(`/admin/adchatmessages/${id}`, {headers: { Authorization: `Bearer ${accessToken}`}})
        setText(messageText)
      } catch {
        message.error('Unexpected error fetching the complete chat message')
      } finally {
        setLoading(false)
      }
    }

    if(!chatMessage.isComplete) {
      getCompleteChatText(accessToken, chatMessage.id)
    } else {
      setText(chatMessage.text)
    }
  }, [chatMessage.id, chatMessage.text, chatMessage.isComplete, accessToken])

  const deleteMessage = async (accessToken, messageId) => {
    try {
      setLoading(true)
      await axios.delete(`/admin/adchatmessages/${messageId}`, { headers: { Authorization: `Bearer ${accessToken}` }})
      onMessageDeleted()
    } catch (error) {
      resolveError(error)
    } finally {
      setLoading(false)
      onClosed()
    }
  }

  const deleteChatPartner = async (accessToken, chatPartnerId) => {
    try {
      setLoading(true)
      await axios.delete(`/admin/users/${chatPartnerId}`, { headers: { Authorization: `Bearer ${accessToken}` }})
      onUserDeleted()
    } catch (error) {
      resolveError(error)
    } finally {
      setLoading(false)
      onClosed()
    }
  }

  return (
    <>
      <Header redColor={true} text="ÄNDRA CHATT-MEDDELANDE" />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <div className="col-12">
                        <label
                          className="form-control-label"
                          htmlFor="input-images"
                        >
                          Bild
                        </label>
                      </div>
                      {chatMessage.image ?
                        <div className="col">
                          <div className="mb-3">
                            <img className="main-content-img" alt="Bild" src={chatMessage.image} />
                          </div>
                        </div>
                        : <div className="col"><label>Inga data</label></div>
                      }
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-message"
                          >
                            Meddelande
                          </label>
                          <textarea
                            className="form-control form-control-alternative"
                            id="input-message"
                            rows="7"
                            value={text}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-user"
                          >
                            Användare
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-user"
                            type="text"
                            value={chatMessage.chatPartner}
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row className='align-items-center justify-content-start'>
                      <Col lg="12">
                        {!loading ?
                          <>
                            <Popconfirm
                              placement="topLeft" title='Är du säker på att du vill radera chatt-meddelandet?'
                              onConfirm={(e) => { e.preventDefault(); deleteMessage(accessToken, chatMessage.id)}}
                              okText="Ja" cancelText="Nej"
                            >
                              <Button color="primary" type="submit" >
                                Radera meddelande
                              </Button>
                            </Popconfirm>
                            <Popconfirm
                              placement="topLeft" title='Är du säker på att du vill radera användaren?'
                              onConfirm={(e) => { e.preventDefault(); deleteChatPartner(accessToken, chatMessage.chatPartnerId)}}
                              okText="Ja" cancelText="Nej"
                            >
                              <Button color="primary" type="submit" >
                                Radera användare
                              </Button>
                            </Popconfirm>
                          </>
                          : (<Spinner color='danger' type='border' style={{marginLeft: '25px', marginTop: '5px'}}></Spinner>)
                        }
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AdChatMessageComponent
