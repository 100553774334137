import React from 'react'
import { axios as apiClient } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Button,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Spinner,
  CardTitle
} from 'reactstrap'
import Header from '../../../components/Headers/Header.js'
import { resolvePagingOffset } from '../../../utils/ListHelper'
import { Modal as ModalAntd, Input, Popconfirm, Checkbox } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { resolveError } from '../../../components/Errors/ErrorSummary'

class UserRolesList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      array: [],
      pageIndex: 0,
      pageSize: 15,
      orderBy: '',
      desc: null,
      phoneNumberFilter: null,
      txtSearch: '',
      modalVisible: false,
      selectedUser: null,
      selectedUserRoles: [],
      updatingRole: false
    }

    this.changePageIndex = this.changePageIndex.bind(this)
    this.onSort = this.onSort.bind(this)

    this.renderSortIcon = this.renderSortIcon.bind(this)
    this.navigate = this.navigate.bind(this)

    this.endpoint = '/admin/users'
    this.storageKey = 'admin-users'
    this.route = 'admin-users'

    // TODO: Could be contructed with data from the API
    this.systemRoles = [
      { label: 'Administratör', value: 'administrator' },
      { label: 'Klubb användare', value: 'clubuser' },
      { label: 'App användare', value: 'user', disabled: true }
    ]
  }

  async componentDidUpdate(prevProps, prevState) {
    if ((this.state.pageIndex !== prevState.pageIndex) || (this.state.orderBy !== prevState.orderBy) || (this.state.desc !== prevState.desc) || (this.state.phoneNumberFilter !== prevState.phoneNumberFilter)) {
      await this.fetchData()
    }

    if (this.state.pageIndex !== prevState.pageIndex) {
      sessionStorage.setItem(`${this.storageKey}-PageIndex`, JSON.stringify(this.state.pageIndex))
    }
    if (this.state.phoneNumberFilter !== prevState.phoneNumberFilter) {
      sessionStorage.setItem(`${this.storageKey}-Filter`, JSON.stringify(this.state.phoneNumberFilter))
    }

    if (this.props.accessToken !== prevProps.accessToken) {
      await this.fetchData()
    }
  }

  fetchData = async () => {
    try {
      const skip = resolvePagingOffset(this.state.pageIndex, this.state.pageSize)
      const orderBy = this.state.orderBy ? `&orderBy=${this.state.orderBy}${this.state.desc ? 'Desc': 'Asc' }` : ''
      const filter = this.state.phoneNumberFilter ? `&phoneNumber=${encodeURIComponent(this.state.phoneNumberFilter)}` : ''
      const response = await apiClient.get(`${this.endpoint}?take=${this.state.pageSize}&skip=${skip}${orderBy}${filter}`, {
        headers: {
          Authorization: `Bearer ${this.props.accessToken}`,
        },
      })
      await this.setState({
        array: response.data,
      })
      sessionStorage.setItem(this.storageKey, JSON.stringify(response.data))
    } catch (error) {
      resolveError(error)
    }
  }

  componentDidMount() {
    const array = JSON.parse(sessionStorage.getItem(this.storageKey))
    const pageIndex = JSON.parse(sessionStorage.getItem(`${this.storageKey}-PageIndex`))
    const filter = JSON.parse(sessionStorage.getItem(`${this.storageKey}-Filter`))

    if (array !== null) {
      this.setState({ array: array })
    }

    if (pageIndex !== null) {
      this.setState({pageIndex: pageIndex})
    }

    if (filter !== null) {
      this.setState({phoneNumberFilter: filter, txtSearch: filter})
    }
  }

  async changePageIndex(number, data, type) {
    if (type === 'sub') {
      if (number >= 0) {
        this.setState({
          pageIndex: number
        })
      }
    } else if (type === 'add') {
      if (number >= 0 && data.length >= this.state.pageSize) {
        this.setState({
          pageIndex: number
        })
      }
    }
  }

  onSort(e, sortKey) {
    if (this.state.desc === null) {
      this.setState({
        orderBy: sortKey,
        desc: false,
      })
    } else {
      this.setState({
        orderBy: sortKey,
        desc: !this.state.desc,
      })
    }
  }

  onSearch = (value) => {
    this.setState({
      phoneNumberFilter: value,
      pageIndex: 0
    })
  }

  renderSortIcon(columnName) {
    return this.state.orderBy === columnName ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null
  }

  async navigate(item) {
    this.setState({
      selectedUser: item,
      selectedUserRoles: item?.roles?.map(role => role.name.toLowerCase()) ?? []
    })
    this.toggleModal()
  }

  toggleModal = () => {
    this.setState({
      modalVisible: !this.state.modalVisible
    })
  }

  onRoleChange = (selectedRoles) => {
    this.setState({
      selectedUserRoles: selectedRoles
    })
  }

  showDeleteConfirm = async (accessToken, user, role) => {
    const { confirm } = ModalAntd

    confirm({
      title: `Är du säker på att du vill ta bort användarens ${this.resolveRoleName(role.name)} roll?`,
      icon: <ExclamationCircleOutlined />,
      content: `${user.firstName} ${user.lastName} (${user.phoneNumber})`,
      okType: 'danger',
      okText: 'Ja',
      cancelText: 'Avbryt',
      onOk: async () => {
        await this.revokeRole(accessToken, user, role.name)
      },
      onCancel() {},
    })
  }

  saveUserRoles = async (accessToken, user, roles) => {
    try {
      this.setState({ updatingRole: true })
      let userRoles = user.roles?.map(role => role.name.toLowerCase())

      let revokedRoles = userRoles.filter(x => !roles.includes(x))
      let grantedRoles = roles.filter(x => !userRoles.includes(x))

      for (const grantedRole of grantedRoles) {
        await this.grantRole(accessToken, user, grantedRole)
      }
      for (const revokedRole of revokedRoles) {
        await this.revokeRole(accessToken, user, revokedRole)
      }

      this.fetchData()
    } catch (error) {
      resolveError(error)
    } finally {
      this.setState({updatingRole: false })
      this.toggleModal()
    }
  }

  grantRole = async (accessToken, user, roleName) => {
    try {
      await apiClient.put(`/users/${user.id}/role/${this.resolveRoleKey(roleName)}/grant`, null, { headers: {Authorization: `Bearer ${accessToken}`} })
    } catch (error) {
      resolveError(error)
    }
  }

  revokeRole = async (accessToken, user, roleName) => {
    try {
      await apiClient.put(`/users/${user.id}/role/${this.resolveRoleKey(roleName)}/revoke`, null, { headers: { Authorization: `Bearer ${accessToken}` }})
    } catch (error) {
      resolveError(error)
    }
  }

  resolveRoleName = (roleName) => {
    switch(roleName.toLowerCase()) {
    case 'administrator':
      return 'Administratör'
    case 'clubuser':
      return 'Klubb användare'
    case 'user':
      return 'App användare'
    default:
      return roleName //(?)throw new Error('Role not found')
    }
  }

  resolveRoleKey = (roleName) => {
    switch(roleName.toLowerCase()) {
    case 'administrator':
      return 'admin'
    default:
      return roleName
    }
  }

  render = () => {
    const { Search } = Input
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col md="4">
                      <h3 className="mb-0">Hantera roller</h3>
                    </Col>
                    <Col md="4" className="text-md-center mb-2 mb-md-0">
                      <Search
                        allowClear
                        style={{ width: 250 }}
                        placeholder="Telefonnummer +46"
                        onSearch={this.onSearch}
                        value={this.state.txtSearch}
                        onChange={(e) => this.setState({ txtSearch: e.target.value})}
                      />
                    </Col>
                    <Col className="text-md-right" md="4">&nbsp;</Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive style={{ minHeight: 140 }}>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Profilbild</th>
                      <th onClick={e => this.onSort(e, 'firstName')} scope="col">Förnamn {this.renderSortIcon('firstName')}</th>
                      <th onClick={e => this.onSort(e, 'lastName')} scope="col">Efternamn {this.renderSortIcon('lastName')}</th>
                      <th onClick={e => this.onSort(e, 'phoneNumber')} scope="col">Telefonnummer {this.renderSortIcon('phoneNumber')}</th>
                      <th scope="col">Roller</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.array.length > 0
                      ? this.state.array.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {item.profilePicture ? <img className="img-in-listtable mainpicture-with-radius" alt="Bild" src={item.profilePicture} /> : null}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.firstName}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.lastName}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.phoneNumber}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.roles?.map(role => this.resolveRoleName(role.name)).join(', ') ?? ''}
                                </span>
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      href="#"
                                      onClick={(e) => { e.preventDefault(); this.navigate(item) }}
                                    >
                                      Ändra
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                      : null}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {this.state.pageIndex !== 0 ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                            >
                              {(this.state.pageIndex).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem className="active">
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex, this.state.array, 'add') }}
                        >
                          {(this.state.pageIndex + 1).toString()}
                        </PaginationLink>
                      </PaginationItem>
                      {this.state.array.length >= this.state.pageSize ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                            >
                              {(this.state.pageIndex + 2).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem>
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
              <Modal isOpen={this.state.modalVisible} toggle={this.toggleModal} backdrop="static" keyboard={false} unmountOnClose={true}>
                <ModalBody>
                  <Card className="bg-secondary shadow">
                    <CardTitle>
                      <label
                        className="form-control-label"
                        htmlFor="input-user-role-select">
                          Hantera roller
                      </label>
                    </CardTitle>
                    <CardBody>
                      <Form>
                        <div className="pl-lg-4">
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                >
                                  {this.state.selectedUser?.firstName} {this.state.selectedUser?.lastName} ({this.state.selectedUser?.phoneNumber})
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="6">
                              <FormGroup>
                                <Checkbox.Group
                                  id="input-user-role-select"
                                  options={this.systemRoles}
                                  onChange={this.onRoleChange}
                                  value={this.state.selectedUserRoles}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                        <div className="pl-lg-4">
                          <Row className='align-items-center justify-content-start'>
                            <Col lg="12">
                              {!this.state.updatingRole ?
                                <>
                                  <Popconfirm
                                    placement="topLeft" title='Är du säker på att du vill ändra rollerna?'
                                    onConfirm={(e) => { e.preventDefault(); this.saveUserRoles(this.props.accessToken, this.state.selectedUser, this.state.selectedUserRoles)}}
                                    okText="Ja" cancelText="Nej"
                                  >
                                    <Button color="success" type="submit" >
                                      Spara
                                    </Button>
                                  </Popconfirm>
                                  <Button color="danger" onClick={(e) => {e.preventDefault(); this.toggleModal()}}>Tillbaka</Button>
                                </>
                                : (<Spinner color='danger' type='border' style={{marginLeft: '25px', marginTop: '5px'}}></Spinner>)
                              }
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </ModalBody>
              </Modal>
            </div>
          </Row>
        </Container>
      </>
    )
  };
}

export default withRouter(UserRolesList)
