

const latitudeIsValid = ({ latitude }) => {
  return ((latitude?.length > 0 ?? false) && isNumeric(latitude))
}

const longitudeIsValid = ({ longitude }) => {
  return ((longitude?.length > 0 ?? false) && isNumeric(longitude))
}

const isNumeric = (str) => {
  if (typeof str != 'string') { 
    return false 
  }

  return !isNaN(str) && !isNaN(parseFloat(str))
}

export { latitudeIsValid, longitudeIsValid }
