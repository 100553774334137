import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Button,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from 'reactstrap'
import { Input } from 'antd'
import Header from '../../../components/Headers/Header.js'
import { resolvePagingOffset } from '../../../utils/ListHelper'

class AdList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      array: [],
      pageIndex: 0,
      pageSize: 15,
      orderBy: 'statusUpdated',
      desc: false,
      showStatus: 'inreview',
      textFilter: null,
      txtSearch: ''
    }

    this.delObject = this.delObject.bind(this)
    this.changePageIndex = this.changePageIndex.bind(this)
    this.onSort = this.onSort.bind(this)
    this.handleStatus = this.handleStatus.bind(this)
  }

  async componentDidUpdate(prevProps, prevState) {
    if ((this.state.showStatus !== prevState.showStatus)
      ||(this.state.pageIndex !== prevState.pageIndex)
      || (this.state.orderBy !== prevState.orderBy)
      || (this.state.desc !== prevState.desc)
      || (this.state.textFilter !== prevState.textFilter)
    ) {
      await this.fetchData()
    }

    if (this.state.pageIndex !== prevState.pageIndex) {
      sessionStorage.setItem('adPageIndex', JSON.stringify(this.state.pageIndex))
    }
    if (this.state.textFilter !== prevState.textFilter) {
      sessionStorage.setItem('adPageFilter', JSON.stringify(this.state.textFilter))
    }

    if (this.props.accessToken !== prevProps.accessToken) {
      await this.fetchData()
    }
  }

  fetchData = async () => {
    try {
      const skip = resolvePagingOffset(this.state.pageIndex, this.state.pageSize)
      const orderBy = this.state.orderBy ? `&orderBy=${this.state.orderBy}${this.state.desc ? 'Desc': 'Asc' }` : ''
      const status = (this.state.showStatus !== '' && this.state.showStatus !== 'all') ? `&status=${this.state.showStatus}` : ''
      const filter = this.state.textFilter ? `&containsText=${encodeURIComponent(this.state.textFilter)}` : ''

      const response = await axios.get(`/admin/ads?take=${this.state.pageSize}&skip=${skip}${status}${orderBy}${filter}`, {
        headers: {
          Authorization: `Bearer ${this.props.accessToken}`,
        },
      })
      await this.setState({
        array: response.data,
      })
      sessionStorage.setItem('ad', JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  componentDidMount() {
    const array = JSON.parse(sessionStorage.getItem('ad'))
    const pageIndex = JSON.parse(sessionStorage.getItem('adPageIndex'))
    const filter = JSON.parse(sessionStorage.getItem('adPageFilter'))

    if (array !== null) {
      this.setState({
        array: array,
      })
    }

    if (pageIndex !== null) {
      this.setState({
        pageIndex: pageIndex,
      })
    }

    if (filter !== null) {
      this.setState({textFilter: filter, txtSearch: filter})
    }

    // notification.open({
    //   message: 'Hej, Steve här!',
    //   description:
    //     'När du skapar ett event så kan du välja en plats. Om du lämnar några fält tomma och har valt en plats så kommer den hämta all information ifrån platsen. På detta sätt kan du smidigt skapa upp en plats och sen göra flera event som hämtar samma information ifrån platsen utan någon anledning till att kopiera och klista in!',
    //   placement: 'bottomRight',
    // })
  }

  async changePageIndex(number, data, type) {
    if (type === 'sub') {
      if (number >= 0) {
        this.setState({
          pageIndex: number
        })
      }
    } else if (type === 'add') {
      if (number >= 0 && data.length >= this.state.pageSize) {
        this.setState({
          pageIndex: number
        })
      }
    }
  }

  async delObject(accessToken, adId) {
    try {
      await axios.delete(`/ads/${adId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      await this.fetchData()
    } catch (err) {
      console.log(err)
    }
  }

  async onSort(event, sortKey) {
    if (this.state.desc === null) {
      await this.setState({
        orderBy: sortKey,
        desc: false,
      })
    } else {
      await this.setState({
        orderBy: sortKey,
        desc: !this.state.desc,
      })
    }
  }

  onSearch = async (value) => {
    await this.setState({
      textFilter: value,
      pageIndex: 0
    })
  }

  async handleStatus(value) {
    this.setState({
      showStatus: value,
      pageIndex: 0,
    })
  }

  reviewAd = async (item) => {
    this.props.history.push(`/admin/ad/${item.id}`)
    window.location.reload()
  }



  showTitle(key) {
    switch(key) {
    case 'all':
      return 'Alla'
    case 'reviewfailed':
      return 'Avböjda'
    case 'published':
      return 'Publicerade'
    case 'sold':
      return 'Sålda'
    case 'inreview':
      return 'Ogranskade'
    case 'removed':
      return 'Borttagna'
    default:
      return 'Alla'
    }
  }

  showColStatus(key) {
    switch(key) {
    case 'reviewfailed':
      return 'Avböjd'
    case 'published':
      return 'Publicerad'
    case 'sold':
      return 'Såld'
    case 'inreview':
      return 'Ogranskad'
    case 'removed':
      return 'Borttagen'
    default:
      return ''
    }
  }

  render = () => {
    var moment = require('moment')
    const { Search } = Input
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center justify-content-between">
                    <Col md="4">
                      <h3 className="mb-0">{this.showTitle(this.state.showStatus)} annonser</h3>
                    </Col>
                    <Col md="4" className="text-md-center mb-2 mb-md-0">
                      <Search placeholder="Sök efter annons..."
                        allowClear
                        onSearch={this.onSearch}
                        style={{ width: 250 }}
                        value={this.state.txtSearch}
                        onChange={(e) => this.setState({ txtSearch: e.target.value})}
                      />
                    </Col>
                    <Col className="text-md-right" md="4">
                      {this.state.showStatus === 'all' ? (
                        <>
                          <Button
                            color="primary"
                            href="#"
                            size="sm"
                            onClick={() => this.handleStatus('inreview')}
                          >
                            Visa ogranskade annonser
                          </Button>
                        </>
                      ) : null}
                      {this.state.showStatus === 'inreview' ? (
                        <>
                          <Button
                            color="primary"
                            href="#"
                            size="sm"
                            onClick={() => this.handleStatus('sold')}
                          >
                            Visa sålda annonser
                          </Button>
                        </>
                      ) : null}
                      {this.state.showStatus === 'sold' ? (
                        <>
                          <Button
                            color="primary"
                            href="#"
                            size="sm"
                            onClick={() => this.handleStatus('published')}
                          >
                            Visa publicerade annonser
                          </Button>
                        </>
                      ) : null}
                      {this.state.showStatus === 'published' ? (
                        <>
                          <Button
                            color="primary"
                            href="#"
                            size="sm"
                            onClick={() => this.handleStatus('reviewfailed')}
                          >
                            Visa avböjda annonser
                          </Button>
                        </>
                      ) : null}
                      {this.state.showStatus === 'reviewfailed' ? (
                        <>
                          <Button
                            color="primary"
                            href="#"
                            size="sm"
                            onClick={() => this.handleStatus('removed')}
                          >
                            Visa borttagna annonser
                          </Button>
                        </>
                      ) : null}
                      {this.state.showStatus === 'removed' ? (
                        <>
                          <Button
                            color="primary"
                            href="#"
                            size="sm"
                            onClick={() => this.handleStatus('all')}
                          >
                            Visa alla annonser
                          </Button>
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {this.state.showStatus === 'all' && <th scope="col">Status</th> }
                      <th onClick={e => this.onSort(e, 'title')} scope="col">Titel {this.state.orderBy === 'title' ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null}</th>
                      <th onClick={e => this.onSort(e, 'description')} scope="col">Beskrivning {this.state.orderBy === 'description' ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null}</th>
                      <th onClick={e => this.onSort(e, 'askingPrice')} scope="col">Pris {this.state.orderBy === 'askingPrice' ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null}</th>
                      <th onClick={e => this.onSort(e, 'supportingClubPercentage')} scope="col">Klubb procent {this.state.orderBy === 'supportingClubPercentage' ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null}</th>
                      <th onClick={e => this.onSort(e, 'statusUpdated')} scope="col">Status uppdaterad datum {this.state.orderBy === 'statusUpdated' ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null}</th>
                      <th scope="col">Kategori</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.array.length > 0
                      ? this.state.array.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              {this.state.showStatus === 'all' &&
                                <td onClick={() => { this.reviewAd(item) }}>
                                  <span className="mb-0 text-sm">
                                    { this.showColStatus(item.status?.toLowerCase()) ?? '' }
                                  </span>
                                </td>
                              }
                              <td onClick={() => { this.reviewAd(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.title}
                                </span>
                              </td>
                              <td onClick={() => { this.reviewAd(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.description.length > 35 ? (item.description.substring(0, 35) + '...') : item.description}
                                </span>
                              </td>
                              <td onClick={() => { this.reviewAd(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.askingPrice} kr
                                </span>
                              </td>
                              <td onClick={() => { this.reviewAd(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.supportingClubPercentage}%
                                </span>
                              </td>
                              <td onClick={() => { this.reviewAd(item) }}>
                                <span className="mb-0 text-sm">
                                  {moment(item.statusUpdated).format('YYYY-MM-DD HH:mm')}
                                </span>
                              </td>
                              <td onClick={() => { this.reviewAd(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.categoryName}
                                </span>
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem onClick={() => { this.reviewAd(item) }}>
                                      Ändra
                                    </DropdownItem>
                                    <DropdownItem
                                      href="#"
                                      onClick={(e) => { e.preventDefault(); this.delObject(this.props.accessToken, item.id) }}
                                    >
                                      Radera
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                      : null}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {this.state.pageIndex !== 0 ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                            >
                              {(this.state.pageIndex).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem className="active">
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex, this.state.array, 'add') }}
                        >
                          {(this.state.pageIndex + 1).toString()}
                        </PaginationLink>
                      </PaginationItem>
                      {this.state.array.length >= this.state.pageSize ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                            >
                              {(this.state.pageIndex + 2).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem>
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  };
}

export default withRouter(AdList)
