import React from 'react'
import { Container } from 'reactstrap'
import Header from '../Headers/Header'
import Loader from '../Loader/Loader'
import { ErrorSummary } from '../Errors/ErrorSummary'

const EntityLoaderComponent = ({ loading, entityName, notFound, errors}) => {
  
  if(loading) { 
    return (
      <>
        <Header />
        <Container className="mt-4" fluid>
          <h3 style={{textAlign: 'center', paddingTop: 25}}>
            <Loader items={5} color="danger" type="grow" size="sm" />
          </h3>
        </Container>
      </>
    )
  } else {
    return (
      <>
        <Header redColor={true} />
        <Container className="mt-4" fluid>
          <h1>
            Åh nej!
            <br />
            {notFound ? (
              <>Kunde tyvärr inte hitta {entityName}.</>
            ) : errors ? (
              <ErrorSummary errors={errors} />
            ) : (
              <>Ett okänt fel inträffade. Var vänlig försök igen.</>
            )}
          </h1>
        </Container>
      </>
    )
  }
}
 
export default EntityLoaderComponent