import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
  Modal,
  ModalBody
} from 'reactstrap'
import Header from '../../../components/Headers/Header.js'
import { ErrorSummary, resolveError } from '../../../components/Errors/ErrorSummary'
import 'antd/dist/antd.css'
import { Popconfirm, Avatar, List, Tooltip } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import EntityLoaderComponent from '../../../components/Loader/EntityLoaderComponent'
import ClubSearchComponent from '../../../components/Clubs/ClubSearchComponent'
import { isNil } from 'lodash'

class UserEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: null,
      subscription: null,
      errors: null,
      loading: true,
      notFound: false,
      modalClubSearchVisible: false,
      selectingClub: false
    }

    this.getData = this.getData.bind(this)
    this.putData = this.putData.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  async componentDidMount() {
    const token = JSON.parse(localStorage.getItem('accessToken'))
    if (token) {
      await this.getData(token, this.props.match.params.userId)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  async getData(accessToken, userId) {
    try {
      this.setState({ loading: true })
      const {data: user} = await axios.get(`/admin/users/${userId}`, { headers: { Authorization: `Bearer ${accessToken}` }})

      if(!isNil(user) && user.isSubscriber) {
        try {
          const {data: subscription} = await axios.get(`/admin/subscriptions/${userId}/active`, { headers: { Authorization: `Bearer ${accessToken}` }})
          this.setState({
            subscription
          })
        } catch {
          resolveError(new Error('Kunde tyvärr inte hitta medlemskapet.'))
        }
      }

      this.setState({
        object: {
          ...this.state.object,
          ...user,
        },
      })
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  async putData(accessToken, userId, data) {
    try {
      this.setState({ loading: true })
      const tempData = {
        ...data
      }
      await axios.put(`/admin/users/${userId}`, tempData, { headers: { Authorization: `Bearer ${accessToken}` } })
      this.props.history.push('/admin/user')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  handleInput(key, value) {
    let stateValue = value
    if(key === 'teamName' && (!isNil(value) && value.trim().length === 0)) {
      stateValue = null
    }

    this.setState(prevState => ({
      object: {
        ...prevState.object,
        [key]: stateValue
      }
    }))
  }

  grantFreeSub = async (accessToken, userId) => {
    try {
      this.setState({ loading: true })
      await axios.post(`/admin/subscriptions/${userId}`, {}, { headers: {Authorization: `Bearer ${accessToken}`} })
      this.props.history.push('/admin/user')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  revokeSub = async (accessToken, userId, subscriptionId) => {
    try {
      this.setState({ loading: true })
      await axios.put(`/admin/subscriptions/${userId}/revoke/${subscriptionId}`, null, { headers: {Authorization: `Bearer ${accessToken}`} })
      this.props.history.push('/admin/user')
      window.location.reload()
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        loading: false
      })
    }
  }

  toggleClubSearchModal = () => {
    this.setState({
      modalClubSearchVisible: !this.state.modalClubSearchVisible
    })
  }

  addClub = async (accessToken, clubId) => {
    try {
      this.setState({ selectingClub: true })
      if(this.state.object.supportingClubs.find(club => club.id === clubId)) {
        return
      }

      const {data: club} = await axios.get(`/admin/clubs/${clubId}`, { headers: { Authorization: `Bearer ${accessToken}` }})
      this.setState(prevState => ({
        object: {
          ...prevState.object,
          supportingClubs: [...prevState.object.supportingClubs, club]
        }
      }))
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    } finally {
      this.setState({ selectingClub: false })
      this.toggleClubSearchModal()
    }
  }

  deleteClub = (clubId) => {
    this.setState(prevState => ({
      object: {
        ...prevState.object,
        supportingClubs: prevState.object.supportingClubs.filter(club => club.id !== clubId)
      }
    }))
  }

  sortClubsByName = (clubs) => {
    return clubs.sort(function(a, b) {
      const nameA = a?.name.toUpperCase() ?? ''
      const nameB = b?.name.toUpperCase() ?? ''
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      // names must be equal
      return 0
    })
  }

  render = () => {
    const { object, loading, subscription } = this.state
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Ändra</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <div className="col-12">
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                                Profilbild
                            </label>
                          </div>
                          {object.profilePicture &&
                              <div className="col">
                                <div className="mb-3">
                                  <img className="main-content-img round-img" alt="Bild" src={object.profilePicture} />
                                </div>
                              </div>
                          }
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Förnamn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.firstName}
                                onChange={(e) => this.handleInput('firstName', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Efternamn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.lastName}
                                onChange={(e) => this.handleInput('lastName', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-club"
                              >
                                Första klubb
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-club"
                                type="text"
                                value={object.supportingClub?.name ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-team-name"
                              >
                                Lagnamn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-team-name"
                                type="text"
                                value={object.teamName ?? ''}
                                onChange={(e) => this.handleInput('teamName', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                E-post
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                type="text"
                                value={object.email}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-phonenumber"
                              >
                                Telefonnummer
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-phonenumber"
                                type="text"
                                value={object.phoneNumber}
                                onChange={(e) => this.handleInput('phoneNumber', e.target.value)}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-categories"
                              >
                                Favorit kategorier
                              </label>
                              <textarea
                                className="form-control form-control-alternative"
                                id="input-categories"
                                rows="2"
                                value={object.favoriteCategories?.map(cItem => cItem.name).join(', ') ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Row className="align-items-center">
                              <Col xs="8">
                                <label
                                  className="form-control-label"
                                  htmlFor="input-club"
                                >
                                  Klubbar
                                </label>
                              </Col>
                              <Col className="text-right" xs="4">
                                <Button
                                  color="success"
                                  size="sm"
                                  onClick={(e) => { e.preventDefault(); this.toggleClubSearchModal() }}
                                >
                                  Lägg till klubb
                                </Button>
                              </Col>
                            </Row>
                            <Row className='align-items-center justify-content-start'>
                              <Col xs="12">
                                <List
                                  itemLayout="horizontal"
                                  dataSource={this.sortClubsByName(object.supportingClubs)}
                                  renderItem={(item) => (
                                    <List.Item
                                      actions={[
                                        <Popconfirm key={item.id} placement="topLeft" title='Är du säker på att du vill radera klubben?'
                                          onConfirm={(e) => { e.preventDefault(); this.deleteClub(item.id)}}
                                          okText="Ja" cancelText="Nej">
                                          <Tooltip title="Radera klubb" placement='left'>
                                            <span>
                                              <DeleteOutlined />
                                            </span>
                                          </Tooltip>
                                        </Popconfirm>
                                      ]}
                                    >
                                      <List.Item.Meta
                                        avatar={<Avatar src={item.logo} />}
                                        title={item.name}
                                        description={item.description}
                                      />
                                    </List.Item>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4 pt-4">
                        <Row className='align-items-center justify-content-start'>
                          <Col lg="12">
                            {!loading ?
                              <>
                                <Button
                                  color="success"
                                  type="submit"
                                  onClick={(e) => { e.preventDefault(); this.putData(this.props.accessToken, this.props.match.params.userId, this.state.object) }}
                                >
                                  Spara
                                </Button>
                                {!object.isSubscriber &&
                                  <Popconfirm
                                    placement="topLeft" title='Är du säker på att du vill ge ett gratis medlemskap?'
                                    onConfirm={(e) => { e.preventDefault(); this.grantFreeSub(this.props.accessToken, this.props.match.params.userId) }}
                                    okText="Ja" cancelText="Nej"
                                  >
                                    <Button color="success" type="submit" >
                                      Ge gratis medlemskap
                                    </Button>
                                  </Popconfirm>
                                }
                                {subscription &&
                                  <Popconfirm
                                    placement="topLeft" title={`Är du säker på att du vill ta bort medlemskapet '${subscription.productId}'?`}
                                    onConfirm={(e) => { e.preventDefault(); this.revokeSub(this.props.accessToken, this.props.match.params.userId, subscription.id) }}
                                    okText="Ja" cancelText="Nej"
                                  >
                                    <Button color="danger" type="submit" >
                                      Ta bort medlemskap
                                    </Button>
                                  </Popconfirm>
                                }
                              </>
                              : (<Spinner color='danger' type='border' style={{marginLeft: '25px', marginTop: '5px'}}></Spinner>)
                            }

                          </Col>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
          <Modal isOpen={this.state.modalClubSearchVisible} toggle={this.toggleClubSearchModal} backdrop="static" keyboard={false} unmountOnClose={true}>
            <ModalBody>
              <ClubSearchComponent
                accessToken={this.props.accessToken}
                title='Sök efter klubb'
                primaryBtnText='Välj klubb'
                confirmText='Är du säker?'
                loading={this.state.selectingClub}
                onClubSelected={(clubId) => this.addClub(this.props.accessToken, clubId)}
                onClosed={this.toggleClubSearchModal}
              />
            </ModalBody>
          </Modal>
        </>
      )
    } else {
      return (
        <EntityLoaderComponent
          loading={this.state.loading}
          entityName="användaren"
          errors={this.state.errors}
          notFound={this.state.notFound} />
      )
    }
  }
}

export default withRouter(UserEdit)
