import React from 'react'
import { withRouter } from 'react-router-dom'
import { axios } from '../../../utils/api-config'
import {
  Button,
  Container,
  Row,
  Col,
} from 'reactstrap'
import DashboardHeaderCard from '../../../components/Headers/DashboardHeaderCard'
import { resolveError } from '../../../components/Errors/ErrorSummary'
import EntityLoaderComponent from '../../../components/Loader/EntityLoaderComponent'
import * as XLSX from 'xlsx'
import moment from 'moment'

class ClubStatistics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      club: null,
      totalSupportingUsers: 0,
      totalGiftedMoney: 0,
      totalAds: 0,
      totalAdsSoldAtPrice: 0,
      totalActiveSubs: 0,
      totalUserConsumedCoupons: 0,
      totalReceipts: {
        monthlyTotalAmount: 0,
        yearlyTotalAmount: 0
      },
      statsLoaded: false,
      loading: false,
      errors: null,
      notFound: false
    }
  }

  async componentDidMount() {
    try {
      this.setState({ loading: true })
      const token = JSON.parse(localStorage.getItem('accessToken'))
      if (token) {
        await this.loadClub(token, this.props.match.params.clubId)
        await this.loadStats(token, this.props.match.params.clubId)
      }
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      this.setState({ loading: false })
    }
  }

  loadStats = async (token, clubId) => {
    this.setState({ statsLoaded: false })
    const stats = await this.fetchStats(token, clubId)

    await this.setState({
      statsLoaded: true,
      totalSupportingUsers: stats.totalSupportingUsers,
      totalGiftedMoney: stats.totalGiftedMoney,
      totalAds: stats.totalAds,
      totalAdsSoldAtPrice: stats.totalAdsSoldAtPrice,
      totalActiveSubs: stats.totalActiveSubs,
      totalUserConsumedCoupons: stats.totalUserConsumedCoupons,
      totalReceipts: stats.totalReceipts
    })
  }

  loadClub = async (accessToken, clubId) => {
    const {data : club} = await axios.get(`/clubs/${clubId}`, { headers: { Authorization: `Bearer ${accessToken}`}})
    this.setState({ club })
  }

  fetchStats = async (accessToken, clubId) => {
    const statsBaseEndpoint = `/admin/stats/clubs/${clubId}`

    let endpoints = [
      `${statsBaseEndpoint}/total-supporting-users`,
      `${statsBaseEndpoint}/total-gifted-money`,
      `${statsBaseEndpoint}/total-ads`,
      `${statsBaseEndpoint}/total-ads-sold-at-price`,
      `${statsBaseEndpoint}/total-active-subs`,
      `${statsBaseEndpoint}/total-user-consumed-coupons`,
      `${statsBaseEndpoint}/total-receipts`,
    ]

    const {data: totalSupportingUsers} =      await axios.get(endpoints[0], { headers: { Authorization: `Bearer ${accessToken}`} })
    const {data: totalGiftedMoney} =          await axios.get(endpoints[1], { headers: { Authorization: `Bearer ${accessToken}`} })
    const {data: totalAds} =                  await axios.get(endpoints[2], { headers: { Authorization: `Bearer ${accessToken}`} })
    const {data: totalAdsSoldAtPrice} =       await axios.get(endpoints[3], { headers: { Authorization: `Bearer ${accessToken}`} })
    const {data: totalActiveSubs} =           await axios.get(endpoints[4], { headers: { Authorization: `Bearer ${accessToken}`} })
    const {data: totalUserConsumedCoupons} =  await axios.get(endpoints[5], { headers: { Authorization: `Bearer ${accessToken}`} })
    const {data: totalReceipts} =             await axios.get(endpoints[6], { headers: { Authorization: `Bearer ${accessToken}`} })

    return { totalSupportingUsers, totalGiftedMoney, totalAds, totalAdsSoldAtPrice, totalActiveSubs, totalUserConsumedCoupons, totalReceipts }
  }

  exportExcel = () => {
    let data = [
      {
        'Klubb':                  this.state.club?.name ?? 'N/A',
        'Antalet användare': this.state.totalSupportingUsers,
        'Skänkta pengar':     this.state.totalGiftedMoney,
        'Antalet annonser':              this.state.totalAds,
        'Såldsumma':  this.state.totalAdsSoldAtPrice,
        'Antalet prenumeranter':      this.state.totalActiveSubs,
        'Använda kuponger':     this.state.totalUserConsumedCoupons,
        'Kvitton månadsvis':  this.state.totalReceipts.monthlyTotalAmount,
        'Kvitton årsvis':  this.state.totalReceipts.yearlyTotalAmount,
      }
    ]

    let wb = XLSX.utils.book_new()
    let ws = XLSX.utils.json_to_sheet(data)
    XLSX.utils.book_append_sheet(wb, ws, 'Klubb statistik')
    XLSX.writeFile(wb, `Statistik_${this.state.club?.name ?? 'Klubb'}_${moment().format('YYYY-MM-DD')}.xlsx`)
  }

  renderCardDescription = (description) => {
    return (
      <p className="mt-3 mb-0 text-muted text-sm">
        <span>{description ? description : '\u00A0'}</span>
      </p>
    )
  }

  render() {
    if(this.state.club && this.state.statsLoaded && !this.state.errors) {
      return (
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <Row className="align-items-center justify-content-between pb-4">
              <Col md="4">
                <h3 className="mb-0" ref={(el) => el && el.style.setProperty('color', 'white', 'important')}>
                  {this.state.club?.name}
                </h3>
              </Col>
              <Col className="text-md-right" md="4">
                { this.state.club?.logo &&
                  <span className="avatar avatar-sm">
                    <img src={this.state.club?.logo} alt="Symbol" />
                  </span>
                }
              </Col>
            </Row>
            <div className="header-body">
              <Row className="mb-xl-4">
                <Col lg="6" xl="3">
                  <DashboardHeaderCard
                    title="Antalet användare"
                    value={this.state.totalSupportingUsers} unit="st"
                    color="bg-warning" icon="fa-users"
                  >
                    {this.renderCardDescription('Antal användare registrerade för klubben')}
                  </DashboardHeaderCard>
                </Col>
                <Col lg="6" xl="3">
                  <DashboardHeaderCard
                    title="Skänkta pengar"
                    value={parseFloat(this.state.totalGiftedMoney).toFixed(2)} unit="kr"
                    color="bg-info" icon="fa-chart-line"
                  >
                    {this.renderCardDescription('Hur mycket som har skänkts till klubben')}
                  </DashboardHeaderCard>
                </Col>
                <Col lg="6" xl="3">
                  <DashboardHeaderCard
                    title="Antalet annonser"
                    value={this.state.totalAds} unit="st"
                    color="bg-yellow" icon="fa-chart-area"
                  >
                    {this.renderCardDescription('Antal annonser')}
                  </DashboardHeaderCard>
                </Col>
                <Col lg="6" xl="3">
                  <DashboardHeaderCard
                    title="Såldsumma"
                    value={parseFloat(this.state.totalAdsSoldAtPrice).toFixed(2)} unit="kr"
                    color="bg-info" icon="fa-chart-line"
                  >
                    {this.renderCardDescription('Hur mycket har sålt för klubben')}
                  </DashboardHeaderCard>
                </Col>
              </Row>
              <Row className="mb-xl-4">
                <Col lg="6" xl="3">
                  <DashboardHeaderCard
                    title="Antalet prenumeranter"
                    value={this.state.totalActiveSubs} unit="st"
                    color="bg-yellow" icon="fa-chart-area"
                  >
                    {this.renderCardDescription('Antal användare som är prenumeranter')}
                  </DashboardHeaderCard>
                </Col>
                <Col lg="6" xl="3">
                  <DashboardHeaderCard
                    title="Använda kuponger"
                    value={this.state.totalUserConsumedCoupons} unit="st"
                    color="bg-warning" icon="fa-users"
                  >
                    {this.renderCardDescription('Antalet kuponger som har använts av användare')}
                  </DashboardHeaderCard>
                </Col>
                <Col lg="6" xl="3">
                  <DashboardHeaderCard
                    title="Kvitton månadsvis"
                    value={this.state.totalReceipts.monthlyTotalAmount} unit="st"
                    color="bg-success" icon="fa-chart-line"
                  >
                    {this.renderCardDescription('Antalet kvitton för månadsvis')}
                  </DashboardHeaderCard>
                </Col>
                <Col lg="6" xl="3">
                  <DashboardHeaderCard
                    title="Kvitton årsvis"
                    value={this.state.totalReceipts.yearlyTotalAmount} unit="st"
                    color="bg-success" icon="fa-chart-line"
                  >
                    {this.renderCardDescription('Antalet kvitton för årsvis')}
                  </DashboardHeaderCard>
                </Col>
              </Row>
              <Row className='align-items-center justify-content-end'>
                <Col lg="12" className="text-md-right">
                  <Button
                    color="info"
                    type="submit"
                    onClick={(e) => { e.preventDefault(); this.exportExcel() }}
                  >
                    Exportera excel
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      )
    } else {
      return (
        <EntityLoaderComponent
          loading={this.state.loading}
          entityName="klubbens statistics"
          errors={this.state.errors}
          notFound={this.state.notFound} />
      )
    }
  }
}

export default withRouter(ClubStatistics)
