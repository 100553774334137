import { initializeApp } from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyANvKml2KXeov4brmlfMDL1MOlhCClV8IY',
  authDomain: 'uzitsweden.firebaseapp.com',
  projectId: 'uzitsweden',
  storageBucket: 'uzitsweden.appspot.com',
  messagingSenderId: '783588182905',
  appId: '1:783588182905:web:49a38fbc37a3bdf23abda8',
  measurementId: 'G-ZCW16KRT7C'
}

const firebaseApp = initializeApp(firebaseConfig)

export { firebaseApp }
