import React from 'react'
import { Row, Col } from 'reactstrap'
import { PropTypes } from 'prop-types'
import moment from 'moment'

const GrowthRatePeriodSelector = (props) => {
  if(!props.visible) {
    return null
  }

  const { periods, onPeriodChanged, margin } = props
  const token = JSON.parse(localStorage.getItem('accessToken'))
  const now = moment().format('YYYY-MM-DD')

  return (
    <Row>
      <Col className={`${margin}`} xl="8">
        { periods.map((period, index) => (
          <button style={{ cursor: 'pointer'}} key={index} onClick={() => onPeriodChanged(token, now, period)}>
            {period}
          </button>
        ))}
      </Col>
    </Row>
  )
}

GrowthRatePeriodSelector.defaultProps = {
  visible: false,
  periods: [7, 15, 30, 120, 365],
  onPeriodChanged: () => {}, //noop
  margin: 'pt-2 mb-0 mb-xl-0'
}
GrowthRatePeriodSelector.propTypes = {
  visible: PropTypes.bool,
  periods: PropTypes.arrayOf(PropTypes.number),
  onPeriodChanged: PropTypes.func,
  margin: PropTypes.string
}

export default GrowthRatePeriodSelector
