import { getAuth, signOut } from 'firebase/auth'

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const auth = getAuth()
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    },
    reject)
  })
}

const refreshToken = async () => {
  try {
    let user = await getCurrentUser()
    if(user) {
      let token = await user.getIdToken(true)
      return token
    } else {
      throw new Error('User is logged out')
    }
  } catch(err) {
    console.log(err)
    throw err
  }
}

const logOut = async () => {
  const auth = getAuth()
  await signOut(auth)
  sessionStorage.clear()
  localStorage.clear()
}

export { getCurrentUser, refreshToken, logOut }
