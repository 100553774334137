import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Col,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Input
} from 'reactstrap'
import 'antd/dist/antd.css'
import { Select } from 'antd'
import Header from '../../../components/Headers/Header.js'
import { resolvePagingOffset } from '../../../utils/ListHelper'
import { resolveError } from '../../../components/Errors/ErrorSummary'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment'

class SubscriptionList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      array: [],
      pageIndex: 0,
      pageSize: 15,
      orderBy: '',
      desc: null,
      verificationStatus: [],
      selectedSub: null,
      modalDetailVisible: false,
      isErroredFilter: null,
      isActiveFilter: null,
      verifiactionStatusFilter: null,
    }

    this.changePageIndex = this.changePageIndex.bind(this)
    this.onSort = this.onSort.bind(this)

    this.storageKey = 'subscriptions'
  }

  async componentDidMount() {
    const array = JSON.parse(sessionStorage.getItem(this.storageKey))
    const pageIndex = JSON.parse(sessionStorage.getItem(`${this.storageKey}-PageIndex`))
    const filterIsErrored = JSON.parse(sessionStorage.getItem(`${this.storageKey}-Filter-Errored`))
    const filterIsActive = JSON.parse(sessionStorage.getItem(`${this.storageKey}-Filter-Active`))
    const filterStatus = JSON.parse(sessionStorage.getItem(`${this.storageKey}-Filter-Status`))

    if (array !== null) {
      this.setState({
        array: array,
      })
    }

    if (pageIndex !== null) {
      this.setState({pageIndex: pageIndex})
    }

    if (filterIsErrored !== null) {
      this.setState({isErroredFilter: filterIsErrored})
    }
    if (filterIsActive !== null) {
      this.setState({isActiveFilter: filterIsActive})
    }
    if (filterStatus !== null) {
      this.setState({verifiactionStatusFilter: filterStatus})
    }

    const accessToken = JSON.parse(sessionStorage.getItem('accessToken'))
    if (accessToken) {
      await this.loadRelatedData(accessToken)
    } else {
      this.props.history.push('/auth/login')
      window.location.reload()
    }
  }

  loadRelatedData = async (accessToken) => {
    const {data: verificationStatus} = await axios.get('/admin/subscriptions/verificationStatus', { headers: { Authorization: `Bearer ${accessToken}` } })
    this.setState({
      verificationStatus
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    if ((this.state.pageIndex !== prevState.pageIndex)
    || (this.state.orderBy !== prevState.orderBy)
    || (this.state.desc !== prevState.desc)
    || (this.state.isActiveFilter !== prevState.isActiveFilter)
    || (this.state.isErroredFilter !== prevState.isErroredFilter)
    || (this.state.verifiactionStatusFilter !== prevState.verifiactionStatusFilter)
    ) {
      await this.fetchData()
    }

    if (this.state.pageIndex !== prevState.pageIndex) {
      sessionStorage.setItem(`${this.storageKey}-PageIndex`, JSON.stringify(this.state.pageIndex))
    }
    if (this.state.isErroredFilter !== prevState.isErroredFilter) {
      sessionStorage.setItem(`${this.storageKey}-Filter-Errored`, JSON.stringify(this.state.isErroredFilter))
    }
    if (this.state.isActiveFilter !== prevState.isActiveFilter) {
      sessionStorage.setItem(`${this.storageKey}-Filter-Active`, JSON.stringify(this.state.isActiveFilter))
    }
    if (this.state.verifiactionStatusFilter !== prevState.verifiactionStatusFilter) {
      sessionStorage.setItem(`${this.storageKey}-Filter-Status`, JSON.stringify(this.state.verifiactionStatusFilter))
    }

    if (this.props.accessToken !== prevProps.accessToken) {
      await this.fetchData()
    }
  }

  fetchData = async () => {
    try {
      const skip = resolvePagingOffset(this.state.pageIndex, this.state.pageSize)
      const orderBy = this.state.orderBy ? `&orderBy=${this.state.orderBy}${this.state.desc ? 'Desc': 'Asc' }` : ''
      // Filters
      const verifiactionStatusFilter = !isNil(this.state.verifiactionStatusFilter) ? `&verificationStatus=${encodeURIComponent(this.state.verifiactionStatusFilter)}` : ''
      const isActiveFilter = !isNil(this.state.isActiveFilter) ? `&active=${this.state.isActiveFilter}` : ''
      const isErroredFilter = !isNil(this.state.isErroredFilter) ? `&errored=${this.state.isErroredFilter}` : ''
      const filter = `${isActiveFilter}${isErroredFilter}${verifiactionStatusFilter}`

      const response = await axios.get(`/admin/subscriptions?take=${this.state.pageSize}&skip=${skip}${orderBy}${filter}`, {
        headers: {
          Authorization: `Bearer ${this.props.accessToken}`,
        },
      })
      await this.setState({
        array: response.data,
      })
      sessionStorage.setItem(this.storageKey, JSON.stringify(response.data))
    } catch (error) {
      resolveError(error)
    }
  }

  async changePageIndex(number, data, type) {
    if (type === 'sub') {
      if (number >= 0) {
        this.setState({
          pageIndex: number
        })
      }
    } else if (type === 'add') {
      if (number >= 0 && data.length >= this.state.pageSize) {
        this.setState({
          pageIndex: number
        })
      }
    }
  }

  renderSortIcon = (columnName) => {
    return this.state.orderBy === columnName ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null
  }

  onSort(user, sortKey) {
    let desc = this.state.desc === null ? false : !this.state.desc
    this.setState({
      orderBy: sortKey,
      desc,
    })
  }

  onSearch = (key, value) => {
    let val = isEmpty(value) ? null : value
    this.setState({
      [key]: val,
      pageIndex: 0
    })
  }

  clearFilters = () => {
    this.setState({
      isActiveFilter: null,
      isErroredFilter: null,
      verifiactionStatusFilter: null,
      pageIndex: 0
    })
  }

  navigate = (item) => {
    this.setState({
      selectedSub: item
    })
    this.toggleModalDetail()
  }

  toggleModalDetail = () => {
    this.setState({
      modalDetailVisible: !this.state.modalDetailVisible
    })
  }

  render = () => {
    const { Option } = Select
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center justify-content-between">
                    <Col md="5">
                      <h3 className="mb-0">Alla medlemskap</h3>
                    </Col>
                    <Col md="auto" className="text-md-left mb-2 mb-md-0">
                      <label
                        className="form-control-label"
                        htmlFor="search-active"
                      >
                        Felkod:
                      </label>
                      <Select name="search-active"
                        className="form-control-alternative"
                        defaultValue=''
                        style={{ width: 100, marginLeft: 10 }}
                        value={isNil(this.state.isErroredFilter) ? '' : this.state.isErroredFilter}
                        onChange={(val) => this.onSearch('isErroredFilter', val)}>
                        <Option value="">Alla</Option>
                        <Option value="true">Ja</Option>
                        <Option value="false">Nej</Option>
                      </Select>
                    </Col>
                    <Col md="auto" className="text-md-left mb-2 mb-md-0">
                      <label
                        className="form-control-label"
                        htmlFor="search-active"
                      >
                        Aktivt:
                      </label>
                      <Select name="search-active"
                        className="form-control-alternative"
                        defaultValue=''
                        value={isNil(this.state.isActiveFilter) ? '' : this.state.isActiveFilter}
                        style={{ width: 100, marginLeft: 10 }}
                        onChange={(val) => this.onSearch('isActiveFilter', val)}>
                        <Option value="">Alla</Option>
                        <Option value="true">Ja</Option>
                        <Option value="false">Nej</Option>
                      </Select>
                    </Col>
                    <Col md="auto" className="text-md-left mb-2 mb-md-0">
                      <label
                        className="form-control-label"
                        htmlFor="search-active"
                      >
                        Status:
                      </label>
                      <Select name="search-active"
                        className="form-control-alternative"
                        defaultValue=''
                        value={isNil(this.state.verifiactionStatusFilter) ? '' : this.state.verifiactionStatusFilter}
                        style={{ width: 250, marginLeft: 10 }}
                        onChange={(val) => this.onSearch('verifiactionStatusFilter', val)}
                      >
                        <Option value="">Alla</Option>
                        {this.state.verificationStatus.map(status => <Option key={status}>{status}</Option>)}
                      </Select>
                    </Col>
                    <Col className="text-md-right" md="1">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={(e) => {e.preventDefault(); this.clearFilters()}}
                      >
                        Rensa filter
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th onClick={e => this.onSort(e, 'ownerName')} scope="col">Användare {this.renderSortIcon('ownerName')}</th>
                      <th onClick={e => this.onSort(e, 'active')} scope="col">Aktivt {this.renderSortIcon('active')}</th>
                      <th onClick={e => this.onSort(e, 'verificationStatus')} scope="col">Verifieringsstatus {this.renderSortIcon('verificationStatus')}</th>
                      <th onClick={e => this.onSort(e, 'processingErrored')} scope="col">Bearbetningsfel {this.renderSortIcon('processingErrored')}</th>
                      <th onClick={e => this.onSort(e, 'productId')} scope="col">Produkt {this.renderSortIcon('productId')}</th>
                      <th onClick={e => this.onSort(e, 'purchaseDate')} scope="col">Inköpsdatum {this.renderSortIcon('purchaseDate')}</th>
                      <th onClick={e => this.onSort(e, 'expirationDate')} scope="col">Utgångsdatum {this.renderSortIcon('expirationDate')}</th>
                      <th onClick={e => this.onSort(e, 'operatingSystem')} scope="col">Telefonmodell {this.renderSortIcon('operatingSystem')}</th>
                      <th onClick={e => this.onSort(e, 'processingStart')} scope="col">Bearbetningsstart {this.renderSortIcon('processingStart')}</th>
                      <th onClick={e => this.onSort(e, 'processingEnd')} scope="col">Bearbetningsslut</th>
                      <th onClick={e => this.onSort(e, 'processingResult')} scope="col">Bearbetningsresultat</th>
                      <th onClick={e => this.onSort(e, 'retries')} scope="col">Försök</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.array.length > 0
                      ? this.state.array.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.ownerName}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.active ? 'Ja' : 'Nej'}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.verificationStatus}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.processingErrored ? 'Ja' : 'Nej'}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.productId ?? ''}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.purchaseDate ? moment(item.purchaseDate).format('YYYY-MM-DD') : ''}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.expirationDate ? moment(item.expirationDate).format('YYYY-MM-DD') : ''}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.operatingSystem}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.processingStart ? moment(item.processingStart).format('YYYY-MM-DD HH:mm:ss') : ''}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.processingEnd ? moment(item.processingEnd).format('YYYY-MM-DD HH:mm:ss') : ''}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {(item.processingResult?.length ?? 0) > 35 ? (item.processingResult.substring(0, 35) + '...') : item.processingResult}
                                </span>
                              </td>
                              <td onClick={() => { this.navigate(item) }}>
                                <span className="mb-0 text-sm">
                                  {item.retries}
                                </span>
                              </td>
                              <td className="text-right">
                                &nbsp;
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                      : null}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {this.state.pageIndex !== 0 ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                            >
                              {(this.state.pageIndex).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem className="active">
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex, this.state.array, 'add') }}
                        >
                          {(this.state.pageIndex + 1).toString()}
                        </PaginationLink>
                      </PaginationItem>
                      {this.state.array.length >= this.state.pageSize ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                            >
                              {(this.state.pageIndex + 2).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem>
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal isOpen={this.state.modalDetailVisible} toggle={this.toggleModalDetail} backdrop="static" keyboard={false} unmountOnClose={true}>
          <ModalBody>
            <>
              <Header redColor={true} text="MEDLEMSKAP" />
              <Container className="mt--7" fluid>
                <Row>
                  <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary shadow">
                      <CardBody>
                        <Form>
                          <div className="pl-lg-4">
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-owner"
                                  >
                                    Användare
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-owner"
                                    type="text"
                                    value={this.state.selectedSub?.ownerName ?? ''}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-active"
                                  >
                                    Aktivt
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-active"
                                    type="text"
                                    value={isNil(this.state.selectedSub?.active) ? '' : this.state.selectedSub.active ? 'Ja' : 'Nej'}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-verificationStatus"
                                  >
                                    Status
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-verificationStatus"
                                    type="text"
                                    value={this.state.selectedSub?.verificationStatus ?? ''}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-productId"
                                  >
                                    Produkt
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-productId"
                                    type="text"
                                    value={this.state.selectedSub?.productId ?? ''}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-operatingSystem"
                                  >
                                    Telefonmodell
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-operatingSystem"
                                    type="text"
                                    value={this.state.selectedSub?.operatingSystem ?? ''}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-purchaseDate"
                                  >
                                    Inköpsdatum
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-purchaseDate"
                                    type="text"
                                    value={isNil(this.state.selectedSub?.purchaseDate) ? '' : moment(this.state.selectedSub?.purchaseDate).format('YYYY-MM-DD')}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-expirationDate"
                                  >
                                    Utgångsdatum
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-expirationDate"
                                    type="text"
                                    value={isNil(this.state.selectedSub?.expirationDate) ? '' : moment(this.state.selectedSub?.expirationDate).format('YYYY-MM-DD')}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-processingStart"
                                  >
                                    Bearbetningsstart
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-input-processingStart"
                                    type="text"
                                    value={isNil(this.state.selectedSub?.processingStart) ? '' : moment(this.state.selectedSub?.processingStart).format('YYYY-MM-DD HH:mm:ss')}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-processingEnd"
                                  >
                                    Bearbetningsslut
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-input-processingEnd"
                                    type="text"
                                    value={isNil(this.state.selectedSub?.processingEnd) ? '' : moment(this.state.selectedSub?.processingEnd).format('YYYY-MM-DD HH:mm:ss')}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="4">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-processingErrored"
                                  >
                                    Bearbetningsfel
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-processingErrored"
                                    type="text"
                                    value={isNil(this.state.selectedSub?.processingErrored) ? '' : this.state.selectedSub.processingErrored ? 'Ja' : 'Nej'  }
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-processingResult"
                                  >
                                    Bearbetningsresultat
                                  </label>
                                  <textarea
                                    className="form-control form-control-alternative"
                                    id="input-processingResult"
                                    rows="7"
                                    value={this.state.selectedSub?.processingResult ?? ''}
                                    disabled={true}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" onClick={this.toggleModalDetail}>Tillbaka</Button>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}

export default withRouter(SubscriptionList)
