import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Col,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from 'reactstrap'
import { Input } from 'antd'
import Header from '../../../components/Headers/Header.js'
import { resolvePagingOffset } from '../../../utils/ListHelper'
import { resolveError } from '../../../components/Errors/ErrorSummary'
import moment from 'moment'
import { isEmpty, isNil } from 'lodash'

class UserList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      array: [],
      pageIndex: 0,
      pageSize: 15,
      orderBy: '',
      desc: false,
      nameFilter: null,
      txtSearch: ''
    }

    this.changePageIndex = this.changePageIndex.bind(this)
    this.onSort = this.onSort.bind(this)

    this.storageKey = 'users'
  }

  async componentDidUpdate(prevProps, prevState) {
    if ((this.state.pageIndex !== prevState.pageIndex) || (this.state.orderBy !== prevState.orderBy) || (this.state.desc !== prevState.desc) || (this.state.nameFilter !== prevState.nameFilter))
    {
      await this.fetchData()
    }

    if (this.state.pageIndex !== prevState.pageIndex) {
      sessionStorage.setItem(`${this.storageKey}-PageIndex`, JSON.stringify(this.state.pageIndex))
    }
    if (this.state.nameFilter !== prevState.nameFilter) {
      sessionStorage.setItem(`${this.storageKey}-Filter`, JSON.stringify(this.state.nameFilter))
    }

    if (this.props.accessToken !== prevProps.accessToken) {
      await this.fetchData()
    }
  }

  fetchData = async () => {
    try {
      const skip = resolvePagingOffset(this.state.pageIndex, this.state.pageSize)
      const orderBy = this.state.orderBy ? `&orderBy=${this.state.orderBy}${this.state.desc ? 'Desc': 'Asc' }` : ''
      const filter = this.state.nameFilter ? `&name=${encodeURIComponent(this.state.nameFilter)}` : ''
      const response = await axios.get(`/admin/users?take=${this.state.pageSize}&skip=${skip}${orderBy}${filter}`, {
        headers: {
          Authorization: `Bearer ${this.props.accessToken}`,
        },
      })
      this.setState({
        array: response.data,
      })
      sessionStorage.setItem(this.storageKey, JSON.stringify(response.data))
    } catch (error) {
      resolveError(error)
    }
  }

  componentDidMount() {
    const array = JSON.parse(sessionStorage.getItem(this.storageKey))
    const pageIndex = JSON.parse(sessionStorage.getItem(`${this.storageKey}-PageIndex`))
    const filter = JSON.parse(sessionStorage.getItem(`${this.storageKey}-Filter`))

    if (array !== null) {
      this.setState({
        array: array,
      })
    }

    if (pageIndex !== null) {
      this.setState({
        pageIndex: pageIndex,
      })
    }

    if (filter !== null) {
      this.setState({nameFilter: filter, txtSearch: filter})
    }
  }

  async changePageIndex(number, data, type) {
    if (type === 'sub') {
      if (number >= 0) {
        this.setState({
          pageIndex: number
        })
      }
    } else if (type === 'add') {
      if (number >= 0 && data.length >= this.state.pageSize) {
        this.setState({
          pageIndex: number
        })
      }
    }
  }


  onSort(user, sortKey) {
    if (this.state.desc === null) {
      this.setState({
        orderBy: sortKey,
        desc: false,
      })
    } else {
      this.setState({
        orderBy: sortKey,
        desc: !this.state.desc,
      })
    }
  }

  onSearch = (value) => {
    this.setState({
      nameFilter: value,
      pageIndex: 0
    })
  }

  parseArrayString(value) {
    if(isNil(value) || isEmpty(value) || value.trim().length === 0) {
      return ''
    }

    return value.length > 35 ? (value.substring(0, 35) + '...') : value
  }

  render = () => {
    const { Search } = Input
    return (
      <>
        <Header />
        <Container className="mt--7" fluid>
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center justify-content-between">
                    <Col md="4">
                      <h3 className="mb-0">Alla användare</h3>
                    </Col>
                    <Col md="4" className="text-md-center mb-2 mb-md-0">
                      <Search
                        placeholder="Sök efter användare..."
                        allowClear
                        onSearch={this.onSearch}
                        style={{ width: 250 }}
                        value={this.state.txtSearch}
                        onChange={(e) => this.setState({ txtSearch: e.target.value})}
                      />
                    </Col>
                    <Col className="text-md-right" md="4">
                      &nbsp;
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Profilbild</th>
                      <th onClick={e => this.onSort(e, 'created')} scope="col">Skapad datum {this.state.orderBy === 'created' ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null}</th>
                      <th onClick={e => this.onSort(e, 'firstName')} scope="col">Förnamn {this.state.orderBy === 'firstName' ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null}</th>
                      <th onClick={e => this.onSort(e, 'lastName')} scope="col">Efternamn {this.state.orderBy === 'lastName' ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null}</th>
                      <th onClick={e => this.onSort(e, 'phonenumber')} scope="col">Telefonnummer {this.state.orderBy === 'phonenumber' ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null}</th>
                      <th onClick={e => this.onSort(e, 'email')} scope="col">E-post {this.state.orderBy === 'email' ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null}</th>
                      <th scope="col">Aktivt medlemskap</th>
                      <th scope="col">Första klubb</th>
                      <th scope="col">Klubbar</th>
                      <th scope="col">Favoritkategorier</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.array.length > 0
                      ? this.state.array.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <tr>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {item.profilePicture ? <img className="img-in-listtable mainpicture-with-radius" alt="Bild" src={item.profilePicture} /> : null}
                                </span>
                              </td>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {moment(item.created).format('YYYY-MM-DD HH:mm')}
                                </span>
                              </td>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {item.firstName}
                                </span>
                              </td>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {item.lastName}
                                </span>
                              </td>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {item.phoneNumber}
                                </span>
                              </td>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {item.email}
                                </span>
                              </td>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {item.isSubscriber ? 'Ja' : 'Nej'}
                                </span>
                              </td>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {item.supportingClub?.name ?? ''}
                                </span>
                              </td>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {this.parseArrayString(item.supportingClubs?.map(cItem => cItem.name).join(', ') ?? '')}
                                </span>
                              </td>
                              <td onClick={() => { this.props.history.push(`/admin/user/${item.id}`); window.location.reload() }}>
                                <span className="mb-0 text-sm">
                                  {this.parseArrayString(item.favoriteCategories?.map(cItem => cItem.name).join(', ') ?? '')}
                                </span>
                              </td>
                              <td className="text-right">
                                <UncontrolledDropdown>
                                  <DropdownToggle
                                    className="btn-icon-only text-light"
                                    href="#"
                                    role="button"
                                    size="sm"
                                    color=""
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <i className="fas fa-ellipsis-v" />
                                  </DropdownToggle>
                                  <DropdownMenu
                                    className="dropdown-menu-arrow"
                                    right
                                  >
                                    <DropdownItem
                                      href={'/admin/user/' + item.id}
                                    >
                                      Ändra
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          </React.Fragment>
                        )
                      })
                      : null}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination
                      className="pagination justify-content-end mb-0"
                      listClassName="justify-content-end mb-0"
                    >
                      {this.state.pageIndex !== 0 ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                              tabIndex="-1"
                            >
                              <i className="fas fa-angle-left" />
                              <span className="sr-only">Previous</span>
                            </PaginationLink>
                          </PaginationItem>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                            >
                              {(this.state.pageIndex).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem className="active">
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex, this.state.array, 'add') }}
                        >
                          {(this.state.pageIndex + 1).toString()}
                        </PaginationLink>
                      </PaginationItem>
                      {this.state.array.length >= this.state.pageSize ? (
                        <>
                          <PaginationItem>
                            <PaginationLink
                              href=""
                              onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                            >
                              {(this.state.pageIndex + 2).toString()}
                            </PaginationLink>
                          </PaginationItem>
                        </>
                      ) : null}
                      <PaginationItem>
                        <PaginationLink
                          href=""
                          onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                        >
                          <i className="fas fa-angle-right" />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  };
}

export default withRouter(UserList)
