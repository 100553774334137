import React from 'react'
import { axios } from '../../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Card,
  CardHeader,
  Col,
  Table,
  Container,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter
} from 'reactstrap'
import { Input } from 'antd'
import Header from '../../../components/Headers/Header.js'
import { resolvePagingOffset } from '../../../utils/ListHelper'
import { resolveError } from '../../../components/Errors/ErrorSummary'
import EntityLoaderComponent from '../../../components/Loader/EntityLoaderComponent'

var moment = require('moment')

class ReceiptList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      club: null,
      array: [],
      pageIndex: 0,
      pageSize: 15,
      orderBy: '',
      desc: null,
      textFilter: null,
      loading: true,
      errors: null,
      notFound: false
    }

    this.changePageIndex = this.changePageIndex.bind(this)
    this.onSort = this.onSort.bind(this)
  }

  async componentDidUpdate(prevProps, prevState) {
    if ((this.state.pageIndex !== prevState.pageIndex)
    || (this.state.orderBy !== prevState.orderBy)
    || (this.state.desc !== prevState.desc)
    || (this.state.textFilter !== prevState.textFilter)
    ) {
      await this.fetchData()
    }
    if (this.props.accessToken !== prevProps.accessToken) {
      await this.loadClub(this.props.accessToken, this.props.match.params.clubId)    
      await this.fetchData()
    }
  }

  fetchData = async () => {
    try {
      const skip = resolvePagingOffset(this.state.pageIndex, this.state.pageSize)
      const orderBy = this.state.orderBy ? `&orderBy=${this.state.orderBy}${this.state.desc ? 'Desc': 'Asc' }` : ''
      const propsFilter = `&clubId=${this.props.match.params.clubId}`
      const pageFilter = this.state.textFilter ? `&text=${encodeURIComponent(this.state.textFilter)}` : ''

      const response = await axios.get(`/admin/receipts?take=${this.state.pageSize}&skip=${skip}${orderBy}${propsFilter}${pageFilter}`, {
        headers: {
          Authorization: `Bearer ${this.props.accessToken}`,
        },
      })
      this.setState({
        array: response.data,
      })
    } catch (error) {
      resolveError(error)
    }
  }

  loadClub = async (accessToken, clubId) => {
    try {
      await this.setState({ loading: true })
      const response = await axios.get(`/clubs/${clubId}`, 
        { headers: { Authorization: `Bearer ${accessToken}`}}
      )
      this.setState({
        club: response.data
      })
    } catch (error) {
      const errors = resolveError(error)
      await this.setState({
        errors,
        notFound: error?.response?.status === 404
      })
    } finally {
      await this.setState({ loading: false })
    }
  }

  async changePageIndex(number, data, type) {
    if (type === 'sub') {
      if (number >= 0) {
        this.setState({
          pageIndex: number
        })
      }
    } else if (type === 'add') {
      if (number >= 0 && data.length >= this.state.pageSize) {
        this.setState({
          pageIndex: number
        })
      }
    }
  }

  renderSortIcon = (columnName) => {
    return this.state.orderBy === columnName ? (this.state.desc === true ? (<>↓</>) : (<>↑</>)) : null
  }

  async onSort(user, sortKey) {
    let desc = this.state.desc === null ? false : !this.state.desc
    await this.setState({
      orderBy: sortKey,
      desc,
    })
  }

  onSearch = async (value) => {
    await this.setState({
      textFilter: value,
      pageIndex: 0
    })
  }

  navigate = (item) => {
    return item
  }

  render = () => {
    const { club } = this.state
    const { Search } = Input
    if (club) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center justify-content-between">
                      <Col md="4">
                        <h3 className="mb-0">
                          {this.state.club?.name}
                        </h3>
                      </Col>
                      <Col md="4" className="text-md-center mb-2 mb-md-0">
                        <Search placeholder="Sök efter kvitto..." allowClear onSearch={this.onSearch} style={{ width: 250 }} />
                      </Col>
                      <Col className="text-md-right" md="4">
                        <span className="avatar avatar-sm rounded-circle">
                          <img src={this.state.club?.logo} alt="Symbol" />
                        </span>
                      </Col>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th onClick={e => this.onSort(e, 'receiptId')} scope="col">Kvittonummer {this.renderSortIcon('receiptId')}</th>
                        <th onClick={e => this.onSort(e, 'ownerName')} scope="col">Användare {this.renderSortIcon('ownerName')}</th>
                        <th onClick={e => this.onSort(e, 'operatingSystem')} scope="col">Telefonmodell {this.renderSortIcon('operatingSystem')}</th>
                        <th onClick={e => this.onSort(e, 'productId')} scope="col">Produkt {this.renderSortIcon('productId')}</th>
                        <th onClick={e => this.onSort(e, 'purchaseDate')} scope="col">Inköpsdatum {this.renderSortIcon('purchaseDate')}</th>
                        <th onClick={e => this.onSort(e, 'expirationDate')} scope="col">Utgångsdatum {this.renderSortIcon('expirationDate')}</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.array.length > 0
                        ? this.state.array.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td onClick={() => { this.navigate(item) }}>
                                  <span className="mb-0 text-sm">
                                    {item.receiptId}
                                  </span>
                                </td>
                                <td onClick={() => { this.navigate(item) }}>
                                  <span className="mb-0 text-sm">
                                    {item.ownerName}
                                  </span>
                                </td>
                                <td onClick={() => { this.navigate(item) }}>
                                  <span className="mb-0 text-sm">
                                    {item.operatingSystem}
                                  </span>
                                </td>
                                <td onClick={() => { this.navigate(item) }}>
                                  <span className="mb-0 text-sm">
                                    {item.productId ?? ''}
                                  </span>
                                </td>
                                <td onClick={() => { this.navigate(item) }}>
                                  <span className="mb-0 text-sm">
                                    {moment(item.purchaseDate).format('YYYY-MM-DD')}
                                  </span>
                                </td>
                                <td onClick={() => { this.navigate(item) }}>
                                  <span className="mb-0 text-sm">
                                    {moment(item.expirationDate).format('YYYY-MM-DD')}
                                  </span>
                                </td>
                                <td className="text-right">
                                  &nbsp;
                                </td>
                              </tr>
                            </React.Fragment>
                          )
                        })
                        : null}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        {this.state.pageIndex !== 0 ? (
                          <>
                            <PaginationItem>
                              <PaginationLink
                                href=""
                                onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                                tabIndex="-1"
                              >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                              </PaginationLink>
                            </PaginationItem>
                            <PaginationItem>
                              <PaginationLink
                                href=""
                                onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex - 1, this.state.array, 'sub') }}
                              >
                                {(this.state.pageIndex).toString()}
                              </PaginationLink>
                            </PaginationItem>
                          </>
                        ) : null}
                        <PaginationItem className="active">
                          <PaginationLink
                            href=""
                            onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex, this.state.array, 'add') }}
                          >
                            {(this.state.pageIndex + 1).toString()}
                          </PaginationLink>
                        </PaginationItem>
                        {this.state.array.length >= this.state.pageSize ? (
                          <>
                            <PaginationItem>
                              <PaginationLink
                                href=""
                                onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                              >
                                {(this.state.pageIndex + 2).toString()}
                              </PaginationLink>
                            </PaginationItem>
                          </>
                        ) : null}
                        <PaginationItem>
                          <PaginationLink
                            href=""
                            onClick={(e) => { e.preventDefault(); this.changePageIndex(this.state.pageIndex + 1, this.state.array, 'add') }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </>
      )
    } else {
      return (
        <EntityLoaderComponent 
          loading={this.state.loading}
          entityName="klubben"
          errors={this.state.errors}
          notFound={this.state.notFound} />
      )
    }
  };
}

export default withRouter(ReceiptList)
