// Index
import Index from './views/Index'
// Login
import Login from './views/endpoints/Login'
// Ads
import AdList from './views/endpoints/Ads/List'
import AdEdit from './views/endpoints/Ads/Edit'
// User
import UserList from './views/endpoints/User/List'
import UserEdit from './views/endpoints/User/Edit'
// Club
import ClubList from './views/endpoints/Club/List'
import ClubEdit from './views/endpoints/Club/Edit'
import ClubCreate from './views/endpoints/Club/Create'
// Category
import CategoryList from './views/endpoints/Category/List'
import CategoryEdit from './views/endpoints/Category/Edit'
import CategoryCreate from './views/endpoints/Category/Create'
// Assets
// import AssetsList from './views/endpoints/Assets/List'
// Location
import LocationList from './views/endpoints/Location/List'
import LocationEdit from './views/endpoints/Location/Edit'
import LocationCreate from './views/endpoints/Location/Create'
// Partner
import PartnerList from './views/endpoints/Partner/List'
import PartnerCreate from './views/endpoints/Partner/Create'
import PartnerEdit from './views/endpoints/Partner/Edit'

// Ad Chat Messages
import AdChatList from './views/endpoints/Chats/List'

// UserRoles
import UserRolesList from  './views/endpoints/UserRoles/List'

// Receipt
import ClubReceiptList from './views/endpoints/Receipts/ClubReceiptList'
import ReceiptList from './views/endpoints/Receipts/List'

// Subscription
import SubscriptionList from './views/endpoints/Subscriptions/List'

// Club Statistics
import ClubStatisticsList from './views/endpoints/Statistics/ClubStatisticsList'
import ClubStatistics from './views/endpoints/Statistics/ClubStatistics'

// Messaging
import BroadcastMessage from './views/endpoints/Messages/BroadcastMessage'

var routes = [
  {
    menu: true,
    clean: false,
    path: '/dashboard',
    name: 'Översikt',
    component: Index,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/login',
    name: 'Logga in',
    component: Login,
    layout: '/auth',
  },
  {
    menu: true,
    clean: false,
    path: '/ad',
    name: 'Annonser',
    component: AdList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/ad/:adId',
    component: AdEdit,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/user',
    name: 'Användare',
    component: UserList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/user/:userId',
    component: UserEdit,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/role-users',
    name: 'Roll hantering',
    component: UserRolesList,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/club',
    name: 'Klubbar',
    component: ClubList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/club/create',
    component: ClubCreate,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/club/:clubId',
    component: ClubEdit,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/category',
    name: 'Kategorier',
    component: CategoryList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/category/create',
    component: CategoryCreate,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/category/:categoryId',
    component: CategoryEdit,
    layout: '/admin',
  },
  // {
  //   menu: true,
  //   clean: false,
  //   path: '/assets',
  //   name: 'Media',
  //   component: AssetsList,
  //   layout: '/admin',
  // }
  {
    menu: true,
    clean: false,
    path: '/location',
    name: 'Platser',
    component: LocationList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/location/create',
    component: LocationCreate,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/location/:locationId',
    component: LocationEdit,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/partner',
    name: 'Värdepartners',
    component: PartnerList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/partner/create',
    component: PartnerCreate,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/partner/:partnerId',
    component: PartnerEdit,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/chats',
    name: 'Chatt-meddelanden',
    component: AdChatList,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/receipts',
    name: 'Kvitton',
    component: ClubReceiptList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/receipts/:clubId',
    component: ReceiptList,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/subscriptions',
    name: 'Medlemskap',
    component: SubscriptionList,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/statistics',
    name: 'Statistik',
    component: ClubStatisticsList,
    layout: '/admin',
  },
  {
    menu: false,
    clean: false,
    path: '/statistics/:clubId',
    component: ClubStatistics,
    layout: '/admin',
  },
  {
    menu: true,
    clean: false,
    path: '/messages',
    name: 'Notifikationer',
    component: BroadcastMessage,
    layout: '/admin',
  },
]
export default routes
