import React from 'react'
import { axios } from '../../utils/api-config'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner
} from 'reactstrap'
import 'antd/dist/antd.css'
import Header from '../Headers/Header.js'
import { ErrorSummary, resolveError } from '../Errors/ErrorSummary'

class LocationCreateComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      object: {
        name: '',
        coordinates: {
          latitude: '',
          longitude: '',
        },
        parent: { id: this.props.parentLocation.id }
      },
      errors: null,
      loading: false
    }

    this.postData = this.postData.bind(this)
    this.handleInput = this.handleInput.bind(this)
  }

  async postData(accessToken, data) {
    try {
      await this.setState({loading: true})
      const tempData = {
        ...data
      }
      const createResponse = await axios.post('/locations',
        tempData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
      this.props.onLocationCreated(createResponse.data)
    } catch (error) {
      const errors = resolveError(error)
      this.setState({
        errors
      })
    } finally {
      await this.setState({
        loading: false
      })
    }
  }

  async handleInput(key, value) {
    if(key === 'latitude' || key === 'longitude')
    {
      let { coordinates } = this.state.object
      coordinates[key] = value
      await this.setState(prevState => ({
        object: {
          ...prevState.object,
          coordinates
        }
      }))
    } else {
      await this.setState(prevState => ({
        object: {
          ...prevState.object,
          [key]: value
        }
      }))
    }
  }

  render = () => {
    const { object } = this.state
    if (object) {
      return (
        <>
          <Header />
          <Container className="mt--7" fluid>
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="8">
                        <h3 className="mb-0">Skapa ny</h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <h6 className="heading-small text-muted mb-4">
                        Uppgifter
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-parentName"
                              >
                                Underplats till
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-parentName"
                                type="text"
                                value={this.props.parentLocation?.name ?? ''}
                                disabled={true}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-name"
                              >
                                Namn
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-name"
                                type="text"
                                value={object.name}
                                onChange={(e) => this.handleInput('name', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-lat"
                              >
                                Latitude
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-lat"
                                type="text"
                                value={object.coordinates.latitude}
                                onChange={(e) => this.handleInput('latitude', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-long"
                              >
                                Longitude
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-long"
                                type="text"
                                value={object.coordinates.longitude}
                                onChange={(e) => this.handleInput('longitude', e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                      <ErrorSummary errors={this.state.errors} />
                      <div className="pl-lg-4">
                        {!this.state.loading
                          ?
                          (<Button
                            color="success"
                            type="submit"
                            onClick={(e) => { e.preventDefault(); this.postData(this.props.accessToken, this.state.object) }}
                          >
                            Skapa
                          </Button>)
                          :
                          (<Spinner color='danger' type='border' style={{marginLeft: '25px', marginTop: '5px'}}></Spinner>)
                        }
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      )
    } else {
      return null
    }
  };
}

export default withRouter(LocationCreateComponent)
